import React from "react";
import styled from "styled-components";
import Logo from "components/Logo";
import { Link } from "react-router-dom";

const StyledSidebar = styled.div`
  width: 230px;
  background: linear-gradient(180deg, #496490 0%, #28364e 100%);
  color: white;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
`;

const Item = styled.div`
  height: 60px;
  padding-left: 24px;
  font-weight: 600;
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  transition: all 0.1s;
  &:hover {
    background: rgba(0, 0, 0, 0.1);
  }
  &:active {
    background: rgba(0, 0, 0, 0.2);
  }
  &.active {
    background: rgba(0, 0, 0, 0.1);
    &:before {
      content: "";
      width: 2px;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      background: white;
    }
  }
`;

const Sidebar = ({ active }) => (
  <StyledSidebar>
    <Logo />
    <div>
      <Link to="/header">
        <Item className={active === "header" ? "active" : ""}>Header SLS</Item>
      </Link>
      <Link to="/tp">
        <Item className={active === "tp" ? "active" : ""}>Touching Points</Item>
      </Link>
      <Link to="/notifad">
        <Item className={active === "notifad" ? "active" : ""}>Notif Ad</Item>
      </Link>
      <Link to="/loan">
        <Item className={active === "loan" ? "active" : ""}>Loan</Item>
      </Link>
      <Link to="/slide">
        <Item className={active === "slide" ? "active" : ""}>Slide</Item>
      </Link>
      <Link to="/login">
        <Item className={active === "login" ? "active" : ""}>Login Creative</Item>
      </Link>
      <Link to="/insurance">
        <Item className={active === "insurance" ? "active" : ""}>Insurance</Item>
      </Link>
    </div>
  </StyledSidebar>
);

export default Sidebar;
