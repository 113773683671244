const theme = {
  colors: {
    lightGrayBorder: "#d6d8d9",
    darkText: "#253441",
    green: "#63A861",
    lightBlue: "#eef0f1"
  },
  standardRadius: "7px"
};

export default theme;
