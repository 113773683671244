import React, { useEffect, useState } from "react";
import styled from "styled-components";

import Title from "components/Title";

const StyledModal = styled.div`
  width: 500px;
  position: fixed;
  height: 100%;
  z-index: 999;
  right: 0;
  top: 0;
  background: white;
  box-shadow: 0px 4px 100px rgba(0, 0, 0, 0.25);
  animation: ${({ animation }) => animation};
  overflow-y: auto;
  .modalInner {
    padding-bottom: 100px;
  }
`;

const Overlay = styled.div`
  width: 100%;
  position: fixed;
  height: 100%;
  z-index: 998;
  right: 0;
  top: 0;
  background: white;
  opacity: 0.7;
  animation: ${({ animation }) => animation};
`;

const Modal = ({ children, onClose, show }) => {
  const [render, setRender] = useState(show);

  useEffect(() => {
    if (show) setRender(true);
  }, [show]);

  const handleAnimationEnd = (e) => {
    if (!show) setRender(false);
  };

  return (
    render && (
      <>
        <Overlay
          animation={
            show ? "modalOverlayFadeIn 0.5s" : "modalOverlayFadeOut 0.5s"
          }
          onClick={onClose}
        />
        <StyledModal
          onAnimationEnd={handleAnimationEnd}
          animation={show ? "modalFadeIn 0.5s" : "modalFadeOut 0.5s"}
        >
          <div className="modalInner">{children}</div>
        </StyledModal>
      </>
    )
  );
};

const StyledModalFooter = styled.div`
  position: fixed;
  bottom: 0;
  width: 500px;
  min-height: 65px;
  border-top: 1px solid ${({ theme }) => theme.colors.lightGrayBorder};
  padding: 12px;
  background: white;
`;

export const ModalFooter = ({ children }) => {
  return <StyledModalFooter>{children}</StyledModalFooter>;
};

const StyledModalContent = styled.div`
  padding: 50px;
  padding-bottom: 0;
`;

export const ModalContent = ({ children }) => {
  return <StyledModalContent>{children}</StyledModalContent>;
};

const StyledModalTitle = styled.div`
  padding: 70px 50px 0px;
`;

export const ModalTitle = ({ title, subtitle, ...rest }) => {
  return (
    <StyledModalTitle {...rest}>
      <Title title={title} subtitle={subtitle} />
    </StyledModalTitle>
  );
};

const StyledSeparator = styled.div`
  height: 1px;
  background: black;
  opacity: 0.15;
  position: absolute;
  left: -50px;
  width: calc(100% + 100px);
  top: 50%;
`;

const SeparatorContainer = styled.div`
  height: 20px;
  position: relative;
`;

export const Separator = (props) => (
  <SeparatorContainer {...props}>
    <StyledSeparator />
  </SeparatorContainer>
);
export default Modal;
