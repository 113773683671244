import React from "react";
import styled from "styled-components";

export default ({ tabs = [], active, setActive }) => {
  return tabs.length === 0 ? null : (
    <Tabs>
      {tabs.map((tab, index) => (
        <div
          className={active === index ? "active" : ""}
          onClick={() => setActive(index)}
          key={`tab-${index}`}
        >
          <div>{tab}</div>
        </div>
      ))}
    </Tabs>
  );
};

// Styles

const Tabs = styled.div`
  height: 60px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.lightGrayBorder};
  display: flex;
  font-size: 16px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.darkText};
  & > div {
    height: 100%;
    flex: 1;
    cursor: pointer;
    opacity: 0.5;
    transition: opacity 0.15s;
    text-align: center;
    &:hover {
      opacity: 1;
    }
    & > div {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      height: 100%;
    }
    &.active {
      opacity: 1;
      & > div {
        box-shadow: inset 0 -3px 0 0 ${({ theme }) => theme.colors.darkText};
      }
    }
    &:first-child {
      border-right: 1px solid ${({ theme }) => theme.colors.lightGrayBorder};
    }
  }
`;
