import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { ModalContent } from "components/Modal";
import {
  calculateStats,
  getStatsTable,
  getNotifAdStats,
  getCSVData,
} from "helpers";
import DateSelect from "components/DateSelect";
import Checkbox from "components/Checkbox";
import Button from "components/Button";
import { Separator } from "components/Modal";
import { CSVLink } from "react-csv";
import CampaignTabs from "components/CampaignTabs";

const DESKTOP_TAB = 0;

const StatsTabContent = ({ data }) => {
  const [showInterval, setShowInterval] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [csvData, setCsvData] = useState(null);
  const [statsTable, setStatsTable] = useState(null);
  const [activeTab, setActiveTab] = useState(DESKTOP_TAB);

  useEffect(() => {
    if (data.stats) {
      const stats =
        startDate && endDate
          ? calculateStats(data.stats, startDate, endDate)
          : calculateStats(data.stats, "2020-01-01", "2099-01-01");
      let newStatsTable;
      if (
        data.type === "notifad" &&
        new Date(data?.activityPeriod?.from) > new Date(2021, 11, 14)
      ) {
        newStatsTable = getNotifAdStats(stats);
      } else {
        newStatsTable = getStatsTable(stats);
        setCsvData(getCSVData(newStatsTable, startDate, endDate));
      }

      setStatsTable(newStatsTable);
    }
  }, [startDate, endDate, data]);

  return data.type === "notifad" &&
    new Date(data?.activityPeriod?.from) > new Date(2021, 11, 14) ? (
    <>
      <CampaignTabs
        tabs={["Desktop", "Mobile"]}
        active={activeTab}
        setActive={setActiveTab}
      />
      {activeTab === DESKTOP_TAB ? (
        <ModalContent>
          <Checkbox
            options={[{ value: "show", label: "Intervalle specifique" }]}
            value={showInterval}
            onChange={(val) => {
              setShowInterval(val);
              if (val.length === 0) {
                setStartDate(null);
                setEndDate(null);
              }
            }}
            style={{ marginBottom: 16 }}
          />
          {showInterval.length > 0 && (
            <>
              <DateSelect
                value={startDate}
                onChange={setStartDate}
                label="Du (inclus)"
                style={{ marginBottom: 16 }}
              />
              <DateSelect
                value={endDate}
                onChange={setEndDate}
                label="Au (inclus)"
                style={{ marginBottom: 16 }}
              />
            </>
          )}
          {csvData && (
            <CSVLink
              separator={";"}
              filename={`stats-${data.name}-${startDate || ""}---${
                endDate || ""
              }.csv`.replace(/\s+/g, "")}
              data={csvData}
            >
              <Button secondary style={{ margin: "16px 0" }}>
                Télécharger (CSV)
              </Button>
            </CSVLink>
          )}
          <Separator style={{ marginBottom: 36 }} />
          {statsTable && (
            <>
              <StatsList>
                <div className="statsTitle">Bubble</div>
                <div className="statsLine">
                  <div>Impression bubble</div>
                  <div>{statsTable.impression_bubble_desktop}</div>
                </div>
                <div className="statsLine">
                  <div>Click bubble</div>
                  <div>{statsTable.click_bubble_desktop}</div>
                </div>
                <div className="statsLine">
                  <div>CTR bubble</div>
                  <div>{statsTable.ctr_bubble_desktop}</div>
                </div>
              </StatsList>
              <StatsList>
                <div className="statsTitle">Crea</div>
                <div className="statsLine">
                  <div>Impression crea</div>
                  <div>{statsTable.impression_crea_desktop}</div>
                </div>
                <div className="statsLine">
                  <div>Click crea</div>
                  <div>{statsTable.click_crea_desktop}</div>
                </div>
                <div className="statsLine">
                  <div>CTR crea</div>
                  <div>{statsTable.ctr_crea_desktop}</div>
                </div>
              </StatsList>
              <StatsList>
                <div className="statsTitle">Total</div>
                <div className="statsLine">
                  <div>Impression bubble</div>
                  <div>{statsTable.impression_bubble_desktop}</div>
                </div>
                <div className="statsLine">
                  <div>Click crea</div>
                  <div>{statsTable.click_crea_desktop}</div>
                </div>
                <div className="statsLine">
                  <div>CTR total</div>
                  <div>{statsTable.ctr_total_desktop}</div>
                </div>
              </StatsList>
            </>
          )}
        </ModalContent>
      ) : (
        <ModalContent>
          <Checkbox
            options={[{ value: "show", label: "Intervalle specifique" }]}
            value={showInterval}
            onChange={(val) => {
              setShowInterval(val);
              if (val.length === 0) {
                setStartDate(null);
                setEndDate(null);
              }
            }}
            style={{ marginBottom: 16 }}
          />
          {showInterval.length > 0 && (
            <>
              <DateSelect
                value={startDate}
                onChange={setStartDate}
                label="Du (inclus)"
                style={{ marginBottom: 16 }}
              />
              <DateSelect
                value={endDate}
                onChange={setEndDate}
                label="Au (inclus)"
                style={{ marginBottom: 16 }}
              />
            </>
          )}
          {csvData && (
            <CSVLink
              separator={";"}
              filename={`stats-${data.name}-${startDate || ""}---${
                endDate || ""
              }.csv`.replace(/\s+/g, "")}
              data={csvData}
            >
              <Button secondary style={{ margin: "16px 0" }}>
                Télécharger (CSV)
              </Button>
            </CSVLink>
          )}
          <Separator style={{ marginBottom: 36 }} />
          {statsTable && (
            <>
              <StatsList>
                <div className="statsTitle">Bubble</div>
                <div className="statsLine">
                  <div>Impression bubble</div>
                  <div>{statsTable.impression_bubble_msite}</div>
                </div>
                <div className="statsLine">
                  <div>Click bubble</div>
                  <div>{statsTable.click_bubble_msite}</div>
                </div>
                <div className="statsLine">
                  <div>CTR bubble</div>
                  <div>{statsTable.ctr_bubble_msite}</div>
                </div>
              </StatsList>
              <StatsList>
                <div className="statsTitle">Crea</div>
                <div className="statsLine">
                  <div>Impression crea</div>
                  <div>{statsTable.impression_crea_msite}</div>
                </div>
                <div className="statsLine">
                  <div>Click crea</div>
                  <div>{statsTable.click_crea_msite}</div>
                </div>
                <div className="statsLine">
                  <div>CTR crea</div>
                  <div>{statsTable.ctr_crea_msite}</div>
                </div>
              </StatsList>
              <StatsList>
                <div className="statsTitle">Total</div>
                <div className="statsLine">
                  <div>Impression bubble</div>
                  <div>{statsTable.impression_bubble_msite}</div>
                </div>
                <div className="statsLine">
                  <div>Click crea</div>
                  <div>{statsTable.click_crea_msite}</div>
                </div>
                <div className="statsLine">
                  <div>CTR total</div>
                  <div>{statsTable.ctr_total_msite}</div>
                </div>
              </StatsList>
            </>
          )}
        </ModalContent>
      )}
    </>
  ) : (
    <ModalContent>
      <Checkbox
        options={[{ value: "show", label: "Intervalle specifique" }]}
        value={showInterval}
        onChange={(val) => {
          setShowInterval(val);
          if (val.length === 0) {
            setStartDate(null);
            setEndDate(null);
          }
        }}
        style={{ marginBottom: 16 }}
      />
      {showInterval.length > 0 && (
        <>
          <DateSelect
            value={startDate}
            onChange={setStartDate}
            label="Du (inclus)"
            style={{ marginBottom: 16 }}
          />
          <DateSelect
            value={endDate}
            onChange={setEndDate}
            label="Au (inclus)"
            style={{ marginBottom: 16 }}
          />
        </>
      )}
      {csvData && (
        <CSVLink
          separator={";"}
          filename={`stats-${data.name}-${startDate || ""}---${
            endDate || ""
          }.csv`.replace(/\s+/g, "")}
          data={csvData}
        >
          <Button secondary style={{ margin: "16px 0" }}>
            Télécharger (CSV)
          </Button>
        </CSVLink>
      )}
      <Separator style={{ marginBottom: 36 }} />
      {statsTable && (
        <>
          <StatsList>
            <div className="statsTitle">Desktop</div>
            <div className="statsLine">
              <div>Impression desktop</div>
              <div>{statsTable.impression_desktop}</div>
            </div>
            <div className="statsLine">
              <div>Click desktop</div>
              <div>{statsTable.click_desktop}</div>
            </div>
            <div className="statsLine">
              <div>CTR desktop</div>
              <div>{statsTable.ctr_desktop}</div>
            </div>
          </StatsList>
          <StatsList>
            <div className="statsTitle">Msite</div>
            <div className="statsLine">
              <div>Impression msite</div>
              <div>{statsTable.impression_msite}</div>
            </div>
            <div className="statsLine">
              <div>Click msite</div>
              <div>{statsTable.click_msite}</div>
            </div>
            <div className="statsLine">
              <div>CTR msite</div>
              <div>{statsTable.ctr_msite}</div>
            </div>
          </StatsList>
          <StatsList>
            <div className="statsTitle">Android</div>
            <div className="statsLine">
              <div>Impression android</div>
              <div>{statsTable.impression_android}</div>
            </div>
            <div className="statsLine">
              <div>Click android</div>
              <div>{statsTable.click_android}</div>
            </div>
            <div className="statsLine">
              <div>CTR android</div>
              <div>{statsTable.ctr_android}</div>
            </div>
          </StatsList>
          <StatsList>
            <div className="statsTitle">Total</div>
            <div className="statsLine">
              <div>Impression total</div>
              <div>{statsTable.impression_total}</div>
            </div>
            <div className="statsLine">
              <div>Click total</div>
              <div>{statsTable.click_total}</div>
            </div>
            <div className="statsLine">
              <div>CTR total</div>
              <div>{statsTable.ctr_total}</div>
            </div>
          </StatsList>
        </>
      )}
    </ModalContent>
  );
};

export default StatsTabContent;

// Styles

const StatsList = styled.div`
  margin: 40px 0;
  .statsTitle {
    margin-bottom: 13px;
    font-size: 12px;
    font-weight: bold;
  }
  .statsLine {
    &:nth-child(2n) {
      background: rgba(0, 0, 0, 0.03);
    }
    padding: 10px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;
