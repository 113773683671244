import React, { useState } from "react";
import styled from "styled-components";

import { ModalTitle, ModalContent, ModalFooter } from "components/Modal";

import Button from "components/Button";
import FormFields from "./FormFields";
import { create } from "api";
import { useJwtToken } from "helpers";

import { handleChangeAndCleanEmpty, createCampaignValidator } from "helpers";

const AddCampaign = ({ adFormatName, closeModal, refresh, defaultValues }) => {
  const [loadingCreate, setLoadingCreate] = useState(false);
  const jwtToken = useJwtToken();
  const [data, setData] = useState({
    type: adFormatName.short,
    campaignData: { ...defaultValues?.campaignData },
    activityPeriod: {},
  });

  const handleChange = (property, value) =>
    handleChangeAndCleanEmpty(property, value, data, (res) => setData(res));

  const handleSubmit = () => {
    setLoadingCreate(true);
    create(data, jwtToken)
      .then(() => {
        /* eslint-disable-next-line no-unused-expressions */
        refresh?.();
        closeModal();
      })
      .catch(console.log);
  };

  const isValid = createCampaignValidator(adFormatName.short, data);

  return (
    <>
      <ModalTitle subtitle={adFormatName.long} title="Nouvelle campagne" />
      <ModalContent>
        <FormFields
          data={data}
          handleChange={handleChange}
          initialData={{ targeting: true }}
          adFormatName={adFormatName}
        />
      </ModalContent>
      <ModalFooter>
        <ModalFooterContent>
          <Button empty onClick={closeModal}>
            annuler
          </Button>
          <Button
            primary
            onClick={handleSubmit}
            disabled={loadingCreate || !isValid()}
            loading={loadingCreate}
          >
            créer la campagne
          </Button>
        </ModalFooterContent>
      </ModalFooter>
    </>
  );
};

export default AddCampaign;

// Styles

const ModalFooterContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
`;
