import React from "react";
import styled from "styled-components";

import Sidebar from "components/Sidebar";

const Container = styled.div`
  padding-left: 280px;
  padding-top: 50px;
  padding-right: 50px;
  background: #eef0f1;
  min-height: 100vh;
`;

const Layout = ({ children, active }) => (
  <div>
    <Sidebar active={active} />
    <Container>{children}</Container>
  </div>
);

export default Layout;
