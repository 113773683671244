import React, { useState } from "react";
import { ChromePicker } from "react-color";
import styled from "styled-components";

const StyledColorPicker = styled.div`
  .colorBox {
    width: 80px;
    height: 40px;
    border-radius: ${({ theme }) => theme.standardRadius};
    cursor: pointer;
    box-shadow: 0px 2px 2px 0 rgba(0, 0, 0, 0.2);
  }
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 1000;
  }
  .colorPicker {
    position: absolute;
    z-index: 1001;
  }
`;

const Label = styled.div`
  margin-bottom: 12px;
  font-size: 12px;
  font-weight: 600;
`;

const ColorPicker = ({ color, onChange, label, style }) => {
  const [showPicker, setShowPicker] = useState(false);

  const handleColorChange = e => {
    onChange(e.hex);
  };

  return (
    <StyledColorPicker>
      {label && <Label>{label}</Label>}
      {showPicker && (
        <div className="overlay" onClick={() => setShowPicker(false)} />
      )}
      <div
        className="colorBox"
        onClick={() => setShowPicker(true)}
        style={{ ...style, backgroundColor: color }}
      />
      {showPicker && (
        <div className="colorPicker">
          <ChromePicker
            onChange={handleColorChange}
            color={color}
            disableAlpha
          />
        </div>
      )}
    </StyledColorPicker>
  );
};

export default ColorPicker;
