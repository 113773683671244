import React from "react";
import styled from "styled-components";

const StyledInput = styled.input`
  height: 40px;
  border-radius: ${({ theme }) => theme.standardRadius};
  width: ${({ width }) => width || "100%"};
  padding: 0 15px;
  font-weight: 600;
  &::placeholder {
    font-weight: 400;
    color: rgba(0, 0, 0, 0.3);
    opacity: 1;
  }
  ${({ outline, theme }) =>
    outline
      ? `
    background: white;
    border: 1px solid ${theme.colors.lightGrayBorder};
    `
      : `
    background: ${theme.colors.lightBlue};
    box-shadow: inset 0px 2px 2px rgba(0, 0, 0, 0.07);
    `};
`;

const Label = styled.div`
  margin-bottom: 12px;
  font-size: 12px;
  font-weight: 600;
`;

const Warning = styled.p`
  color: red;
`;

const Input = ({ label, warning, style, ...rest }) => (
  <div style={style}>
    <label>
      {label && <Label>{label}</Label>}
      {warning && <Warning>{warning}</Warning>}
      <StyledInput {...rest} />
    </label>
  </div>
);

export default Input;
