import React from "react";
import { Link } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

const RedirectButton = styled.button`
  height: 70px;
  border: 1px solid #367dff;
  border-radius: 10px;
  width: 300px;
  font-size: 20px;
  font-weight: 500;
  background: transparent;
  color: #367dff;
  margin-bottom: 20px;
  cursor: pointer;
  transition: all 0.15s;
  &:hover {
    box-shadow: 0px 2px 4px 0px #00000050;
  }
  &:active {
    transform: scale(0.97);
  }
`;

const LoginButton = styled.button`
  background: green;
  color: white;
  font-weight: bold;
  font-size: 16px;
  width: 110px;
  height: 50px;
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.15s;
  &:hover {
    box-shadow: 0px 2px 4px 0px #00000050;
  }
  &:active {
    transform: scale(0.97);
  }
`;

const LogoutButton = styled.button`
  background: red;
  color: white;
  font-weight: bold;
  font-size: 16px;
  width: 110px;
  height: 50px;
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.15s;
  &:hover {
    box-shadow: 0px 2px 4px 0px #00000050;
  }
  &:active {
    transform: scale(0.97);
  }
`;

const LoggedInCase = ({ logout }) => (
  <>
    <Link to="/header">
      <RedirectButton>Go to the Adserver</RedirectButton>
    </Link>
    <LogoutButton onClick={() => logout()}>Log out</LogoutButton>
  </>
);

const LoggedOutCase = ({ login }) => (
  <LoginButton onClick={login}>Log in</LoginButton>
);

const Home = () => {
  const { isAuthenticated, loginWithRedirect, logout } = useAuth0();

  return (
    <div>
      {isAuthenticated !== null && (
        <Container>
          {isAuthenticated ? (
            <LoggedInCase
              logout={() => logout({ returnTo: window.location.origin })}
            />
          ) : (
            <LoggedOutCase login={loginWithRedirect} />
          )}
        </Container>
      )}
    </div>
  );
};

export default Home;
