import React from "react";
import { ActivePill, InactivePill, DisabledPill } from "components/Pills";
import { formatDate } from "helpers";

export const GMT_DELAY = 1;

export const CAMPAIGN_NAMES = {
  TP: {
    short: "tp",
    long: "Touching Points",
  },
  NOTIFAD: {
    short: "notifad",
    long: "Notif'Ad",
  },
  HEADER: {
    short: "header",
    long: "Header SLS",
  },
  LOAN: {
    short: "loan",
    long: "Loan Package"
  },
  SLIDE: {
    short: "slide",
    long: "Slide"
  },
  LOGIN: {
    short: "login",
    long: "Login Creative"
  },
  INSURANCE: {
    short: "insurance",
    long: "Insurance"
  },
};

export const HEADERS = {
  TP: [
    { text: "Campagne", key: "name" },
    { text: "Client", key: "client", width: 150 },
    {
      text: "Date de creation",
      key: "dateCreated",
      width: 200,
      customRender: (date) => formatDate(date),
    },
    {
      text: "Status",
      key: "active",
      width: 110,
      customRender: (active, { isInactive }) =>
        isInactive ? (
          <DisabledPill />
        ) : active ? (
          <ActivePill />
        ) : (
          <InactivePill />
        ),
    },
  ],
  NOTIFAD: [
    { text: "Campagne", key: "name" },
    { text: "Client", key: "client", width: 150 },
    {
      text: "Date de creation",
      key: "dateCreated",
      width: 200,
      customRender: (date) => formatDate(date),
    },
    {
      text: "Status",
      key: "active",
      width: 110,
      customRender: (active, { isInactive }) =>
        isInactive ? (
          <DisabledPill />
        ) : active ? (
          <ActivePill />
        ) : (
          <InactivePill />
        ),
    },
  ],
  HEADER: [
    { text: "Campagne", key: "name" },
    { text: "Client", key: "client", width: 150 },
    {
      text: "Date de creation",
      key: "dateCreated",
      width: 200,
      customRender: (date) => formatDate(date),
    },
    {
      text: "Status",
      key: "active",
      width: 110,
      customRender: (active, { isInactive }) =>
        isInactive ? (
          <DisabledPill />
        ) : active ? (
          <ActivePill />
        ) : (
          <InactivePill />
        ),
    },
  ],
  LOAN: [
    { text: "Campagne", key: "name" },
    { text: "Client", key: "client", width: 150 },
    {
      text: "Date de creation",
      key: "dateCreated",
      width: 200,
      customRender: (date) => formatDate(date),
    },
    {
      text: "Status",
      key: "active",
      width: 110,
      customRender: (active, { isInactive }) =>
        isInactive ? (
          <DisabledPill />
        ) : active ? (
          <ActivePill />
        ) : (
          <InactivePill />
        ),
    },
  ],
  SLIDE: [
    { text: "Campagne", key: "name" },
    { text: "Client", key: "client", width: 150 },
    {
      text: "Date de creation",
      key: "dateCreated",
      width: 200,
      customRender: (date) => formatDate(date),
    },
    {
      text: "Status",
      key: "active",
      width: 110,
      customRender: (active, { isInactive }) =>
        isInactive ? (
          <DisabledPill />
        ) : active ? (
          <ActivePill />
        ) : (
          <InactivePill />
        ),
    },
  ],
  LOGIN: [
    { text: "Campagne", key: "name" },
    { text: "Client", key: "client", width: 150 },
    {
      text: "Date de creation",
      key: "dateCreated",
      width: 200,
      customRender: (date) => formatDate(date),
    },
    {
      text: "Status",
      key: "active",
      width: 110,
      customRender: (active, { isInactive }) =>
        isInactive ? (
          <DisabledPill />
        ) : active ? (
          <ActivePill />
        ) : (
          <InactivePill />
        ),
    },
  ],
  INSURANCE: [
    { text: "Campagne", key: "name" },
    { text: "Client", key: "client", width: 150 },
    {
      text: "Date de creation",
      key: "dateCreated",
      width: 200,
      customRender: (date) => formatDate(date),
    },
    {
      text: "Status",
      key: "active",
      width: 110,
      customRender: (active, { isInactive }) =>
        isInactive ? (
          <DisabledPill />
        ) : active ? (
          <ActivePill />
        ) : (
          <InactivePill />
        ),
    },
  ],
};

export const NOTIF_CONTENT = {
  CREA: "crea",
  FORM: "form",
  IFRAME: "iframe",
};
