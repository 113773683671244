import React, { useState } from "react";
import styled from "styled-components";

import Layout from "components/Layout";
import Container from "components/Container";
import Title from "components/Title";
import Button from "components/Button";
import Input from "components/Input";
import Modal from "components/Modal";
import Table from "components/Table";
import AddCampaign from "components/AddCampaign";
import CampaignDetails from "components/CampaignDetails";

export default ({
  adFormatName,
  campaignsData,
  tableHeaders,
  refresh,
  defaultValues,
}) => {
  const [showAddModal, setShowAddModal] = useState(false);
  const [showCampaignModal, setShowCampaignModal] = useState(false);
  const [campaignToSee, setCampaignToSee] = useState(null);
  const [activeTab, setActiveTab] = useState(0);
  const [searchValue, setSearchValue] = useState("");

  const getCampaigns = () => {
    const onlyActive = activeTab === 0;
    return campaignsData.filter((el) => {
      if (onlyActive && !el.active) return false;
      return (
        el.name.toLowerCase().includes(searchValue.toLowerCase()) ||
        el.client.toLowerCase().includes(searchValue.toLowerCase())
      );
    });
  };

  const handleRowClick = (rowData) => {
    setShowCampaignModal(true);
    setCampaignToSee(rowData);
  };

  return (
    <Layout active={adFormatName.short}>
      <Container>
        <Content>
          <Modal
            show={showCampaignModal}
            onClose={() => {
              setShowCampaignModal(false);
              setCampaignToSee(null);
            }}
          >
            <CampaignDetails
              adFormatName={adFormatName}
              data={campaignToSee}
              closeModal={() => setShowCampaignModal(false)}
              refresh={refresh}
            />
          </Modal>
          <Modal show={showAddModal} onClose={() => setShowAddModal(false)}>
            <AddCampaign
              closeModal={() => setShowAddModal(false)}
              refresh={refresh}
              adFormatName={adFormatName}
              defaultValues={defaultValues}
            />
          </Modal>
          <div className="titleAndActions">
            <Title title="Liste des campagnes" subtitle={adFormatName.long} />
            <div className="actions">
              <Button
                primary
                disabled={adFormatName.short === "header"}
                onClick={() => setShowAddModal(!showAddModal)}
              >
                nouvelle campagne
              </Button>
              <Input
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
                style={{ marginLeft: 15 }}
                width="230px"
                outline
                placeholder="chercher"
              />
            </div>
          </div>
          <div className="tableContent">
            <Table
              tabs={[
                {
                  text: `Campagnes actives`,
                  color: "#63A861",
                },
                "Toutes les campagnes",
              ]}
              activeTab={activeTab}
              onTabChange={(tab) => setActiveTab(tab)}
              headers={tableHeaders}
              rows={getCampaigns()}
              onRowClick={handleRowClick}
            />
          </div>
        </Content>
      </Container>
    </Layout>
  );
};

// Styles

const Content = styled.div`
  .titleAndActions,
  .actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .tableContent {
    margin-top: 50px;
  }
`;
