import React from "react";
import styled from "styled-components";
import { ReactComponent as LoadIcon } from "assets/load.svg";

const StyledButton = styled.button`
  padding: 0 20px;
  height: 40px;
  border-radius: ${({ theme }) => theme.standardRadius};
  transition: all 0.15s;
  cursor: pointer;
  font-weight: bold;
  font-size: 12px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: white;
  display: inline-flex;
  align-items: center;

  ${({ outline }) =>
    !outline &&
    `
      &:hover {
        box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.15);
      }
      &:active {
        transform: scale(0.97);
        box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.15);
      }
  `}

  ${({ primary }) =>
    primary &&
    `
    background: linear-gradient(180deg, #78CD77 0%, #62A761 100%);
  `}

  ${({ secondary }) =>
    secondary &&
    `
    background: linear-gradient(180deg, #6E88B6 0%, #434C5C 100%);
  `}

  ${({ danger }) =>
    danger &&
    `
    background: linear-gradient(180deg, #F75959 0%, #9E0000 100%);
  `}

  ${({ empty }) =>
    empty &&
    `
    opacity: 0.5;
    &:hover {
        opacity: 1;
        box-shadow: none;
      }
      color: inherit;
      background: transparent;
  `}

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
    &:hover {
      opacity: 0.5;
      box-shadow: none;
    }
    &:active {
      opacity: 0.5;
      box-shadow: none;
      transform: none;
    }
  }

`;

const Button = ({
  children,
  loading,
  loadingMessage = "En cours ...",
  ...rest
}) => (
  <StyledButton {...rest}>
    {loading ? (
      <>
        <span style={{ marginRight: 15 }}>{loadingMessage}</span>
        <LoadIcon
          height="1.6em"
          style={{ animation: "rotate 1s infinite linear", marginLeft: 8 }}
        />
      </>
    ) : (
      children
    )}
  </StyledButton>
);

export default Button;
