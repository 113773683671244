import React, { useState, useEffect } from "react";
import Shell from "components/Shell";
import { getAll } from "api";
import { CAMPAIGN_NAMES, HEADERS, NOTIF_CONTENT } from "config";

const props = {
  adFormatName: CAMPAIGN_NAMES.NOTIFAD,
  tableHeaders: HEADERS.NOTIFAD,
  defaultValues: {
    campaignData: {
      type: NOTIF_CONTENT.CREA,
    },
  },
};

export default () => {
  const [data, setData] = useState([]);

  const refresh = () => {
    getAll(CAMPAIGN_NAMES.NOTIFAD.short)
      .then((data) => setData(data?.ads ?? []))
      .catch(console.log);
  };

  useEffect(() => {
    refresh();
  }, []);

  return <Shell {...props} campaignsData={data} refresh={refresh} />;
};
