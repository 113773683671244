import React from "react";
import styled from "styled-components";

const StyledTitle = styled.div`
  color: ${({ theme }) => theme.colors.darkText};
  .title {
    font-weight: 600;
    font-size: 24px;
    line-height: 40px;
  }
  .subtitle {
    font-size: 14px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
  }
`;

const Title = ({ title, subtitle }) => {
  return (
    <StyledTitle>
      {subtitle && <div className="subtitle">{subtitle}</div>}
      {title && <div className="title">{title}</div>}
    </StyledTitle>
  );
};

export default Title;
