import React from "react";
import styled from "styled-components";

const StyledLogo = styled.div`
  text-align: center;
  font-weight: 900;
  padding: 50px 0;
  font-size: 16px;
`;

const Logo = () => {
  return <StyledLogo>AdServer</StyledLogo>;
};

export default Logo;
