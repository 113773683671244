import React, { useState } from "react";
import styled from "styled-components";

const InputContainer = styled.div`
  border-radius: ${({ theme }) => theme.standardRadius};
  background: ${({ theme }) => theme.colors.lightBlue};
  box-shadow: inset 0px 2px 2px rgba(0, 0, 0, 0.07);
  display: flex;
  position: relative;
  input {
    font-weight: 600;
    display: block;
    background: transparent;
    min-width: 0;
    height: 40px;
    width: 50px;
    text-align: center;
    &::placeholder {
      font-weight: 400;
      color: rgba(0, 0, 0, 0.3);
      opacity: 1;
    }
  }
`;

const Container = styled.div`
  display: flex;
`;

const Label = styled.div`
  margin-bottom: 12px;
  font-size: 12px;
  font-weight: 600;
`;

const Separator = styled.div`
  opacity: 0.3;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-weight: 600;
`;

const combineDateData = data => {
  return `${data.year}-${data.month}-${data.day}T${data.hours}:${data.minutes}:00.000Z`;
};

const padZero = num => {
  if (num === 0 || num === "0" || num === "00") return "00";
  return num > 9 ? String(num) : `0${num}`;
};

const isDateComplete = data => {
  if (!data.day) return false;
  if (!data.month) return false;
  if (!data.year) return false;
  if (!data.hours) return false;
  if (!data.minutes) return false;
  return true;
};

const deconstructDate = date => {
  if (typeof date === "string" && date.length === 24)
    return {
      day: date.substring(8, 10),
      month: date.substring(5, 7),
      year: date.substring(0, 4),
      hours: date.substring(11, 13),
      minutes: date.substring(14, 16)
    };
  return {
    day: "",
    month: "",
    year: "",
    hours: "",
    minutes: ""
  };
};

const getValidDateValue = (key, value) => {
  if (key === "day" && value <= 31 && value > 0) return value;
  else if (key === "month" && value <= 12 && value > 0) return value;
  else if (key === "year" && value >= 2019) return value;
  else if (key === "minutes" && value >= 0 && value <= 59) return value;
  else if (key === "hours" && value >= 0 && value <= 23) return value;
  return "";
};

export default ({ value, onChange, label, ...rest }) => {
  const [dateData, setDateData] = useState(deconstructDate(value));

  const handleChange = (e, key) => {
    const value = e.target.value;
    setDateData({ ...dateData, [key]: value });
  };

  const handleBlur = (e, key) => {
    const value = e.target.value;
    const isNumber = !isNaN(parseInt(value));
    const validValue = isNumber ? getValidDateValue(key, parseInt(value)) : "";
    const finalValue = validValue === "" ? "" : padZero(validValue);
    const resObject = { ...dateData, [key]: finalValue };
    setDateData(resObject);
    if (isDateComplete(resObject) && combineDateData(resObject).length === 24)
      onChange(combineDateData(resObject));
  };

  return (
    <>
      {label && <Label>{label}</Label>}
      <Container {...rest}>
        <InputContainer style={{ marginRight: 16 }}>
          <input
            placeholder="DD"
            value={dateData.day}
            onChange={e => handleChange(e, "day")}
            onBlur={e => handleBlur(e, "day")}
            maxLength="2"
          />
          <Separator style={{ left: 47 }}>/</Separator>
          <input
            placeholder="MM"
            value={dateData.month}
            onChange={e => handleChange(e, "month")}
            onBlur={e => handleBlur(e, "month")}
            maxLength="2"
          />
          <Separator style={{ left: 101 }}>/</Separator>
          <input
            placeholder="YYYY"
            value={dateData.year}
            onChange={e => handleChange(e, "year")}
            onBlur={e => handleBlur(e, "year")}
            style={{ width: 70 }}
            maxLength="4"
          />
        </InputContainer>
        <InputContainer>
          <input
            placeholder="HH"
            value={dateData.hours}
            onChange={e => handleChange(e, "hours")}
            onBlur={e => handleBlur(e, "hours")}
            maxLength="2"
          />
          <Separator style={{ left: 49 }}>:</Separator>
          <input
            placeholder="mm"
            value={dateData.minutes}
            onChange={e => handleChange(e, "minutes")}
            onBlur={e => handleBlur(e, "minutes")}
            maxLength="2"
          />
        </InputContainer>
      </Container>
    </>
  );
};
