import React, { useState, useEffect } from "react";
import Shell from "components/Shell";
import { getAll } from "api";
import { CAMPAIGN_NAMES, HEADERS } from "config";

const props = {
  adFormatName: CAMPAIGN_NAMES.HEADER,
  tableHeaders: HEADERS.HEADER,
};

export default () => {
  const [data, setData] = useState([]);

  const refresh = () => {
    getAll(CAMPAIGN_NAMES.HEADER.short)
      .then((data) => setData(data?.ads ?? []))
      .catch(console.log);
  };

  useEffect(() => {
    refresh();
  }, []);

  return <Shell {...props} campaignsData={data} refresh={refresh} />;
};
