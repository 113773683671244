import React from "react";
import styled, { css } from "styled-components";

const Option = styled.div`
  font-weight: 600;
  font-size: 16px;
  display: flex;
  align-items: center;
  padding: 8px 0;
  cursor: pointer;
  opacity: 0.8;
  .indicator {
    border-radius: 3px;
    margin-right: 15px;
    width: 15px;
    height: 15px;
    background: transparent;
    border: 2px solid white;
    box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.5);
  }
  ${({ disabled }) => disabled &&
  css`
    cursor: default;
    pointer-events: none;
    opacity: 0.5;
  `}

  &:hover {
    opacity: 1;
    .indicator {
      background: ${({ theme }) => theme.colors.lightBlue};
    }
  }
  ${({ checked }) =>
    checked &&
    `
    opacity: 1;
    &:hover {
      .indicator {
        background: #78CD77;
      }
    }
    .indicator {
      background: #78CD77;
    box-shadow: 0px 0px 0px 1px #62A761;

    }
  `}
`;

const Label = styled.div`
  margin-bottom: 12px;
  font-size: 12px;
  font-weight: 600;
`;

const noop = () => {}

const Checkbox = ({ options, value, onChange, label, style }) => {
  const handleClick = val => {
    const newValue = [...value];
    if (newValue.includes(val)) onChange(newValue.filter(el => el !== val));
    else onChange([...newValue, val]);
  };
  return (
    <div style={style}>
      {label && <Label>{label}</Label>}
      {options.map((o={}, i) => (
        <Option
          key={`option_${i}`}
          onClick={o.disabled ? noop : () => handleClick(o.value)}
          checked={o.disabled ? false : value.includes(o.value)}
          disabled={o.disabled}
        >
          <div className="indicator" />
          <div className="text">{o.label}</div>
        </Option>
      ))}
    </div>
  );
};

export default Checkbox;
