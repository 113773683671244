import React, { useEffect, useState, useMemo } from "react";
import Input from "components/Input";
import UploadButton from "components/UploadButton";
import ColorPicker from "components/ColorPicker";
import Checkbox from "components/Checkbox";
import Radio from "components/Radio";
import Targeting from "components/Targeting";
import Button from "components/Button";
import { Separator } from "components/Modal";
import { getClients } from "api";
import CampaignDataFormFields from "components/CampaignDataFormFields";
import { useJwtToken } from "helpers";
import { NOTIF_CONTENT } from "config";
import InputSelect from "./InputSelect";
import { getAll } from "api";
import { CAMPAIGN_NAMES } from "config";
import Modal from "components/Modal";
import CampaignDetails from "components/CampaignDetails";

const HeaderFormFields = ({ data, handleChange, jwtToken }) => {
  return (
    <>
      <Checkbox
        label="Servir sur :"
        options={[
          { value: "desktop", label: "Desktop" },
          { value: "msite", label: "Msite" },
        ]}
        style={{ marginBottom: 36 }}
        value={data.platforms || []}
        onChange={(val) => handleChange("platforms", val)}
      />
      {data.platforms && data.platforms.includes("desktop") && (
        <UploadButton
          url={data.campaignData.desktopCreative || ""}
          onUpload={(url) => handleChange("campaignData.desktopCreative", url)}
          label="Créative desktop"
          style={{ marginBottom: 36 }}
          jwtToken={jwtToken}
        />
      )}

      {data.platforms && data.platforms.includes("msite") && (
        <UploadButton
          url={data.campaignData.msiteCreative || ""}
          onUpload={(url) => handleChange("campaignData.msiteCreative", url)}
          label="Créative MSite"
          style={{ marginBottom: 36 }}
          jwtToken={jwtToken}
        />
      )}

      <Input
        label="Lien de redirection"
        placeholder="http://siteweb.com/lien.html"
        style={{ marginBottom: 36 }}
        value={data.campaignData.redirectLink || ""}
        onChange={(e) =>
          handleChange("campaignData.redirectLink", e.target.value)
        }
      />
    </>
  );
};

const TPFormFields = ({ data, handleChange, jwtToken }) => {
  const [activeSOVCampaign, setActiveSOVCampaign] = useState();
  const currentId = data?._id;
  const currentCategories = useMemo(
    () => data?.targeting?.categories?.map((cat) => cat?.value),
    [data?.targeting?.categories]
  );
  const [showCampaignModal, setShowCampaignModal] = useState();
  const [checkboxState, setCheckboxState] = useState(
    data.campaignData.sov || []
  );

  useEffect(() => {
    async function getActiveSOVCampaign() {
      let activeSOVCampaign = await getAll(CAMPAIGN_NAMES.TP.short);
      activeSOVCampaign = activeSOVCampaign?.ads?.filter(
        ({ _id }) => _id !== currentId
      );
      activeSOVCampaign = activeSOVCampaign?.find(
        ({ active, targeting: { categories } = {}, campaignData: { sov } }) =>
          active &&
          categories?.filter(
            ({ value }) =>
              currentCategories?.includes(value) && sov?.includes("100%")
          )?.length
      );

      setActiveSOVCampaign(activeSOVCampaign);
    }

    getActiveSOVCampaign();
  }, [currentCategories, currentId]);

  const disableSOVField =
    activeSOVCampaign?.campaignData?.sov?.includes("100%");

  useEffect(() => {
    if (disableSOVField) {
      handleChange("campaignData.sov", []);
      return;
    }
    handleChange("campaignData.sov", checkboxState);
  }, [checkboxState, disableSOVField]);

  return (
    <>
      <Checkbox
        label="Share Of Voice:"
        options={[{ value: "100%", label: "100%", disabled: disableSOVField }]}
        style={{ marginBottom: 8 }}
        value={checkboxState}
        onChange={setCheckboxState}
      />
      {disableSOVField ? (
        <Button
          style={{ marginBottom: 36 }}
          danger
          onClick={(e) => {
            e.preventDefault();
            setShowCampaignModal(true);
          }}
        >
          Une campagne est déjà en 100% SOV sur cette cette catégorie:{" "}
          {activeSOVCampaign?.name}{" "}
        </Button>
      ) : null}
      {showCampaignModal ? (
        <Modal
          show={showCampaignModal}
          onClose={() => {
            setShowCampaignModal(false);
          }}
        >
          <CampaignDetails
            adFormatName={CAMPAIGN_NAMES.TP}
            data={activeSOVCampaign}
            closeModal={() => {
              setShowCampaignModal(false);
            }}
          />
        </Modal>
      ) : null}

      <Checkbox
        label="Servir sur :"
        options={[
          { value: "msite", label: "Msite" },
          { value: "desktop", label: "Desktop" },
          { value: "android", label: "Android" },
        ]}
        style={{ marginBottom: 36 }}
        value={data.platforms || []}
        onChange={(val) => handleChange("platforms", val)}
      />

      <Input
        value={data.campaignData.message || ""}
        onChange={(e) => handleChange("campaignData.message", e.target.value)}
        label="Message 1"
        style={{ marginBottom: 36 }}
        warning={
          data.campaignData.message?.length > 34
            ? "Ce champ ne doit pas dépasser 34 caractères!"
            : undefined
        }
      />

      <Input
        value={data.campaignData.message2 || ""}
        onChange={(e) => handleChange("campaignData.message2", e.target.value)}
        label="Message 2"
        style={{ marginBottom: 36 }}
        warning={
          data.campaignData.message2?.length > 43
            ? "Ce champ ne doit pas dépasser 43 caractères!"
            : undefined
        }
      />

      <UploadButton
        url={data.campaignData.clientLogo || ""}
        onUpload={(url) => handleChange("campaignData.clientLogo", url)}
        label="Logo du client"
        style={{ marginBottom: 36 }}
        jwtToken={jwtToken}
      />

      <Input
        label="Lien de redirection"
        placeholder="http://siteweb.com/lien.html"
        style={{ marginBottom: 36 }}
        value={data.campaignData.redirectLink || ""}
        onChange={(e) =>
          handleChange("campaignData.redirectLink", e.target.value)
        }
      />

      <Input
        label="CTA Bouton"
        placeholder="e.g. Voir plus, clicker ici, …"
        style={{ marginBottom: 36 }}
        value={data.campaignData.creaCta}
        onChange={(e) => handleChange("campaignData.creaCta", e.target.value)}
      />
    </>
  );
};

const NotifadFormFields = ({
  data,
  handleChange,
  leadsforceCampaigns,
  jwtToken,
}) => {
  const renderContentFields = (type) => {
    switch (type) {
      case NOTIF_CONTENT.FORM: {
        return (
          <>
            {data?.platforms?.includes("mobile") && (
              <>
                <UploadButton
                  url={data.campaignData.formCreative || ""}
                  onUpload={(url) =>
                    handleChange("campaignData.formCreative", url)
                  }
                  label="Créative du formulaire"
                  style={{ marginBottom: 36 }}
                  jwtToken={jwtToken}
                />
                <Input
                  label="Texte 1"
                  placeholder="Nissan Micra"
                  style={{ marginBottom: 36 }}
                  value={data.campaignData.heading || ""}
                  onChange={(e) =>
                    handleChange("campaignData.heading", e.target.value)
                  }
                />
                <Input
                  label="Texte 2"
                  placeholder="Un design athlétique et audacieux"
                  style={{ marginBottom: 36 }}
                  value={data.campaignData.subheading || ""}
                  onChange={(e) =>
                    handleChange("campaignData.subheading", e.target.value)
                  }
                />
              </>
            )}
            <Input
              label="CTA Formulaire"
              placeholder="J'en profite"
              style={{ marginBottom: 36 }}
              value={data.campaignData.formCta || ""}
              onChange={(e) =>
                handleChange("campaignData.formCta", e.target.value)
              }
            />

            <InputSelect
              label="Campagne Leadsforce"
              options={leadsforceCampaigns?.map(({ campaign, token }) => ({
                label: campaign,
                value: token,
              }))}
              placeholder="Sélectionner une campagne"
              style={{ marginBottom: 36 }}
              value={
                data.campaignData?.leadsforceCampaign
                  ? {
                      value: data.campaignData?.leadsforceCampaign,
                      label: leadsforceCampaigns?.find(
                        ({ token }) =>
                          token === data.campaignData.leadsforceCampaign
                      )?.campaign,
                    }
                  : null
              }
              onChange={(data) =>
                data
                  ? handleChange("campaignData.leadsforceCampaign", data.value)
                  : handleChange("campaignData.leadsforceCampaign", "")
              }
            />
          </>
        );
      }

      case NOTIF_CONTENT.IFRAME: {
        return (
          <Input
            label="Lien iframe"
            placeholder="http://siteweb.com/lien.html"
            value={data.campaignData.iframeLink || ""}
            onChange={(e) =>
              handleChange("campaignData.iframeLink", e.target.value)
            }
          />
        );
      }

      case NOTIF_CONTENT.CREA:
      default:
        return (
          <>
            {data?.platforms?.includes("mobile") && (
              <UploadButton
                url={data.campaignData.bigCreative || ""}
                onUpload={(url) =>
                  handleChange("campaignData.bigCreative", url)
                }
                label="Créative mobile"
                style={{ marginBottom: 36 }}
                jwtToken={jwtToken}
              />
            )}
            <Input
              label="Lien de redirection"
              placeholder="http://siteweb.com/lien.html"
              style={{ marginBottom: 36 }}
              value={data.campaignData.redirectLink || ""}
              onChange={(e) =>
                handleChange("campaignData.redirectLink", e.target.value)
              }
            />
            <Input
              label="CTA Bouton"
              placeholder="e.g. Voir plus, clicker ici, …"
              style={{ marginBottom: 36 }}
              value={data.campaignData.creaCta || ""}
              onChange={(e) =>
                handleChange("campaignData.creaCta", e.target.value)
              }
            />
          </>
        );
    }
  };

  return (
    <>
      <Radio
        options={[
          { label: "Créative", value: NOTIF_CONTENT.CREA },
          { label: "Formulaire (leads)", value: NOTIF_CONTENT.FORM },
          { label: "Iframe", value: NOTIF_CONTENT.IFRAME },
        ]}
        value={data?.campaignData?.type ?? NOTIF_CONTENT.CREA}
        onChange={(val) => handleChange("campaignData.type", val)}
        label="Type d'annonce"
      />

      <Checkbox
        label="Servir sur :"
        options={[
          { value: "mobile", label: "Mobile" },
          { value: "desktop", label: "Desktop" },
        ]}
        style={{ marginBottom: 36 }}
        value={data.platforms || []}
        onChange={(val) => handleChange("platforms", val)}
      />
      <Input
        value={data.campaignData.message || ""}
        onChange={(e) => handleChange("campaignData.message", e.target.value)}
        label="Message"
        style={{ marginBottom: 36 }}
      />
      {data?.platforms?.includes("mobile") && (
        <Input
          value={data.campaignData.title || ""}
          onChange={(e) => handleChange("campaignData.title", e.target.value)}
          label="Titre de la campagne"
          style={{ marginBottom: 36 }}
        />
      )}

      <UploadButton
        url={data.campaignData.thumbnailCreative || ""}
        onUpload={(url) => handleChange("campaignData.thumbnailCreative", url)}
        label="Logo du client"
        style={{ marginBottom: 36 }}
        jwtToken={jwtToken}
      />
      {data?.platforms?.includes("desktop") && (
        <UploadButton
          url={data.campaignData.desktopCrea || ""}
          onUpload={(url) => handleChange("campaignData.desktopCrea", url)}
          label="Créative desktop (min. 950x500)"
          style={{ marginBottom: 36 }}
          jwtToken={jwtToken}
        />
      )}

      {data?.platforms?.includes("mobile") && (
        <ColorPicker
          label="Couleur du background"
          color={data.campaignData.backgroundColor || "#ffffff"}
          onChange={(color) =>
            handleChange("campaignData.backgroundColor", color)
          }
          style={{ marginBottom: 36 }}
        />
      )}

      {renderContentFields(data?.campaignData?.type ?? NOTIF_CONTENT.CREA)}
    </>
  );
};

const LoanFields = ({ data, handleChange, jwtToken }) => {
  return (
    <>
      <Input
        value={data.campaignData.rate || ""}
        onChange={(e) => handleChange("campaignData.rate", e.target.value)}
        label="Taux de crédit"
        style={{ marginBottom: 36 }}
        type="number"
      />

      <Input
        value={data.campaignData.months || ""}
        onChange={(e) => handleChange("campaignData.months", e.target.value)}
        label="Durée (mois)"
        type="number"
        style={{ marginBottom: 36 }}
      />

      <UploadButton
        url={data.campaignData.clientLogo || ""}
        onUpload={(url) => handleChange("campaignData.clientLogo", url)}
        label="Logo du client"
        style={{ marginBottom: 36 }}
        jwtToken={jwtToken}
      />
      <Input
        label="Lien de redirection"
        placeholder="http://siteweb.com/lien.html"
        style={{ marginBottom: 36 }}
        value={data.campaignData.redirectLink || ""}
        onChange={(e) =>
          handleChange("campaignData.redirectLink", e.target.value)
        }
      />
    </>
  );
};

const InsuranceFields = ({ data, handleChange, jwtToken }) => {
  return (
    <>
      <Input
        value={data.campaignData.title || ""}
        onChange={(e) => handleChange("campaignData.title", e.target.value)}
        label="Titre de la campagne"
        style={{ marginBottom: 36 }}
      />

      <Input
        value={data.campaignData.message || ""}
        onChange={(e) => handleChange("campaignData.message", e.target.value)}
        label="Message"
        style={{ marginBottom: 36 }}
      />

      <UploadButton
        url={data.campaignData.clientLogo || ""}
        onUpload={(url) => handleChange("campaignData.clientLogo", url)}
        label="Logo du client"
        style={{ marginBottom: 36 }}
        jwtToken={jwtToken}
      />

      <Input
        value={data.campaignData.message2 || ""}
        onChange={(e) => handleChange("campaignData.message2", e.target.value)}
        label="Client Name"
        style={{ marginBottom: 36 }}
      />

      <Input
        label="Lien de redirection"
        placeholder="http://siteweb.com/lien.html"
        style={{ marginBottom: 36 }}
        value={data.campaignData.redirectLink || ""}
        onChange={(e) =>
          handleChange("campaignData.redirectLink", e.target.value)
        }
      />

      <Input
        label="CTA Bouton"
        placeholder="e.g. Voir plus, clicker ici, …"
        style={{ marginBottom: 36 }}
        value={data.campaignData.creaCta || ""}
        onChange={(e) => handleChange("campaignData.creaCta", e.target.value)}
      />
    </>
  );
};

const SlideFormFields = ({ data, handleChange, jwtToken }) => {
  const [slides, setSlides] = useState([]);
  const creative = data?.campaignData?.creative;
  const [showCampaignModal, setShowCampaignModal] = useState();
  const [campaignToPreview, setCampaignToPreview] = useState();
  const currentCategories = data?.targeting?.categories?.map(
    (cat) => cat?.value
  );
  const currentFrom = data?.activityPeriod?.from;
  const currentTo = data?.activityPeriod?.to;
  const currentCities = data?.targeting?.cities?.map((cat) => cat?.value);
  const currentPriceMin = data?.targeting?.priceMin;
  const currentPriceMax = data?.targeting?.priceMax;
  const currentId = data?._id;

  useEffect(() => {
    async function getSlides() {
      let competingCampaigns = await getAll(CAMPAIGN_NAMES.SLIDE.short);
      competingCampaigns = competingCampaigns?.ads?.filter(
        ({ _id }) => _id !== currentId
      );
      competingCampaigns = competingCampaigns?.filter(
        ({
          active,
          activityPeriod: { from, to },
          targeting: { categories, cities, priceMin, priceMax } = {},
        }) =>
          active &&
          to > currentFrom &&
          from < currentTo &&
          (!currentCategories ||
            !categories ||
            categories?.filter(({ value }) =>
              currentCategories?.includes(value)
            )?.length) &&
          (!currentCities ||
            !cities ||
            cities?.filter(({ value }) => currentCities?.includes(value))
              ?.length) &&
          ([priceMax, currentPriceMin].some((v) => v == null) ||
            priceMax > currentPriceMin) &&
          ([priceMin, currentPriceMax].some((v) => v == null) ||
            priceMin < currentPriceMax)
      );

      setSlides(competingCampaigns);
    }

    getSlides();
  }, [
    creative,
    currentId,
    currentCategories,
    currentFrom,
    data,
    currentTo,
    currentCities,
    currentPriceMin,
    currentPriceMax,
  ]);

  return (
    <>
      {slides?.length ? (
        <>
          <span>Autres campagnes en cours:</span>
          {slides.length > 2 ? (
            <span style={{ color: "red" }}>
              ⚠️ {slides.length} campagnes sont deja en cours avec les memes
              criteres de ciblage, veuillez changer l'un des criteres:
              categorie, ville, prix…
            </span>
          ) : null}
          {slides?.map((slide, index) => {
            return (
              <UploadButton
                url={slide?.campaignData?.creative || ""}
                label={slide?.client}
                style={{ marginBottom: 36 }}
                jwtToken={jwtToken}
                key={`crea ${index}`}
                onClick={(e) => {
                  e.preventDefault();
                  setShowCampaignModal(true);
                  setCampaignToPreview(index);
                }}
                readOnly
              />
            );
          })}{" "}
        </>
      ) : (
        <span>Aucune campagne en cours</span>
      )}

      {showCampaignModal ? (
        <Modal
          show={showCampaignModal}
          onClose={() => {
            setShowCampaignModal(false);
          }}
        >
          <CampaignDetails
            adFormatName={CAMPAIGN_NAMES.SLIDE}
            data={slides?.[campaignToPreview]}
          />
        </Modal>
      ) : null}

      <UploadButton
        url={creative || ""}
        onUpload={(url) => {
          handleChange("campaignData.creative", [url]);
        }}
        label="Creative"
        style={{ marginBottom: 36 }}
        jwtToken={jwtToken}
      />
      <Input
        label="Lien de redirection"
        placeholder="http://siteweb.com/lien.html"
        style={{ marginBottom: 36 }}
        value={data.campaignData.redirectLink || ""}
        onChange={(e) =>
          handleChange("campaignData.redirectLink", e.target.value)
        }
      />
    </>
  );
};

const LoginFormFields = ({ data, handleChange, jwtToken }) => {
  return (
    <>
      <UploadButton
        url={data.campaignData.desktopCrea || ""}
        onUpload={(url) => handleChange("campaignData.desktopCrea", url)}
        label="Créative"
        style={{ marginBottom: 36 }}
        jwtToken={jwtToken}
      />

      <Input
        label="Lien de redirection"
        placeholder="http://siteweb.com/lien.html"
        style={{ marginBottom: 36 }}
        value={data.campaignData.redirectLink || ""}
        onChange={(e) =>
          handleChange("campaignData.redirectLink", e.target.value)
        }
      />
    </>
  );
};

export const fields = {
  header: HeaderFormFields,
  notifad: NotifadFormFields,
  tp: TPFormFields,
  loan: LoanFields,
  slide: SlideFormFields,
  login: LoginFormFields,
  insurance: InsuranceFields,
};

export default ({ data, handleChange, initialData, adFormatName }) => {
  const [clients, setClients] = useState([]);
  const [leadsforceCampaigns, setLeadsforceCampaigns] = useState([]);
  const FormFields = fields[adFormatName.short];
  const jwtToken = useJwtToken();

  useEffect(() => {
    if (!jwtToken) return;

    getClients(jwtToken)
      .then((res) => {
        setClients(res?.clients ?? []);
      })
      .catch(console.log);
  }, [jwtToken]);

  useEffect(() => {
    async function getLeadsforceCampaigns() {
      const response = await window.fetch("/leadsforce", { method: "GET" });
      const json = await response.json();

      setLeadsforceCampaigns(json);
    }

    getLeadsforceCampaigns();
  }, []);

  return (
    <>
      <CampaignDataFormFields
        clients={clients}
        data={data}
        handleChange={handleChange}
      />
      <Separator style={{ marginBottom: 36 }} />
      <FormFields
        jwtToken={jwtToken}
        clients={clients}
        data={data}
        handleChange={handleChange}
        leadsforceCampaigns={leadsforceCampaigns}
      />
      <Separator style={{ marginBottom: 16 }} />
      <Targeting
        hideable={!initialData?.targeting}
        onChange={(data) => handleChange("targeting", data)}
        data={data.targeting || {}}
      />
    </>
  );
};
