import React, { useState } from "react";

import DetailsTabContent from "components/DetailsTabContent";
import CampaignTabs from "components/CampaignTabs";
import FormFields from "components/FormFields";
import StatsTabContent from "components/StatsTabContent";

export default ({ data: initialData, closeModal, refresh, adFormatName }) => {
  const [activeTab, setActiveTab] = useState(0);

  return (
    initialData ? (
      <>
        <CampaignTabs
          tabs={["Détails", "Statistiques"]}
          active={activeTab}
          setActive={setActiveTab}
        />
        {activeTab === 0 ? (
          <DetailsTabContent
            closeModal={closeModal}
            initialData={initialData}
            formFields={FormFields}
            refresh={refresh}
            adFormatName={adFormatName}
          />
        ) : (
          <StatsTabContent data={initialData} />
        )}
      </>
    ) : null
  );
};
