import React, { useEffect, useState } from "react";
import { ActivePill, InactivePill } from "components/Pills";
import { useAuth0 } from "@auth0/auth0-react";
import { NOTIF_CONTENT } from "config";

export const handleChangeAndCleanEmpty = (property, value, data, callback) => {
  const newData = { ...data };
  if (property.includes(".")) {
    const props = property.split(".");
    if (newData[props[0]]) newData[props[0]][props[1]] = value;
    else newData[props[0]] = { [props[1]]: value };
  } else newData[property] = value;

  // clean empty object entries
  Object.keys(newData).forEach((key) => {
    if ((!newData[key] || newData[key] === "") && newData[key] !== false)
      delete newData[key];
  });

  callback(newData);
};

export const formatDataForTable = (el) => [
  el.name,
  el.client,
  el.dateCreated,
  el.active ? <ActivePill /> : <InactivePill />,
];

export const createCampaignValidator = (type, data) => {
  if (type === "header")
    return () => {
      if (!data.client) return false;
      if (!data.name) return false;
      if (!data.platforms) return false;
      if (
        !data.campaignData.desktopCreative &&
        !data.campaignData.msiteCreative
      )
        return false;
      if (!data.campaignData.redirectLink) return false;
      if (!data.isInactive) {
        if (!data.activityPeriod || !data.activityPeriod.from) return false;
        if (!data.activityPeriod || !data.activityPeriod.to) return false;
      }
      if (!data?.targeting?.categories) return false;

      return true;
    };
  if (type === "notifad")
    return () => {
      if (!data.client) return false;
      if (!data.name) return false;
      if (!data.platforms) return false;
      if (!data.campaignData.thumbnailCreative) return false;
      if (data.campaignData.type === NOTIF_CONTENT.CREA) {
        if (!data.campaignData.redirectLink) return false;
        if (data.platforms.includes("mobile") && !data.campaignData.bigCreative)
          return false;
      }

      if (data.campaignData.type === NOTIF_CONTENT.FORM) {
        if (
          data.platforms.includes("mobile") &&
          !data.campaignData.formCreative
        )
          return false;
        if (!data.campaignData.formCta) return false;
        if (data.platforms.includes("mobile") && !data.campaignData.heading)
          return false;
        if (data.platforms.includes("mobile") && !data.campaignData.subheading)
          return false;
      }

      if (data.campaignData.type === NOTIF_CONTENT.IFRAME) {
        if (!data.campaignData.iframeLink) return false;
      }

      if (data.platforms.includes("desktop") && !data.campaignData.desktopCrea)
        return false;
      if (data.platforms.includes("mobile") && !data.campaignData.title)
        return false;
      if (!data.isInactive) {
        if (!data.activityPeriod || !data.activityPeriod.from) return false;
        if (!data.activityPeriod || !data.activityPeriod.to) return false;
      }
      // if (!data?.targeting?.categories) return false;
      return true;
    };

  if (type === "tp")
    return () => {
      if (!data.client) return false;
      if (!data.name) return false;
      if (!data.platforms) return false;
      if (!data.campaignData.message) return false;
      if (!data.campaignData.message2) return false;
      if (!data.campaignData.clientLogo) return false;
      if (!data.campaignData.redirectLink) return false;
      if (!data.campaignData.creaCta) return false;
      if (!data.isInactive) {
        if (!data.activityPeriod || !data.activityPeriod.from) return false;
        if (!data.activityPeriod || !data.activityPeriod.to) return false;
      }
      return true;
    };

  if (type === "loan")
    return () => {
      if (!data.client) return false;
      if (!data.name) return false;
      if (!data.campaignData.clientLogo) return false;
      if (!data.campaignData.rate) return false;
      if (!data.campaignData.months) return false;
      if (!data.isInactive) {
        if (!data.activityPeriod || !data.activityPeriod.from) return false;
        if (!data.activityPeriod || !data.activityPeriod.to) return false;
      }
      return true;
    };

  if (type === "slide")
    return () => {
      if (!data.client) return false;
      if (!data.name) return false;
      if (!data.isInactive) {
        if (!data.activityPeriod || !data.activityPeriod.from) return false;
        if (!data.activityPeriod || !data.activityPeriod.to) return false;
      }
      return true;
    };
  if (type === "login")
    return () => {
      if (!data.client) return false;
      if (!data.name) return false;
      if (!data.campaignData.desktopCrea) return false;
      if (!data.isInactive) {
        if (!data.activityPeriod || !data.activityPeriod.from) return false;
        if (!data.activityPeriod || !data.activityPeriod.to) return false;
      }

      return true;
    };

  if (type === "insurance")
    return () => {
      if (!data.name) return false;
      if (!data.client) return false;
      if (!data.campaignData.title) return false;
      if (!data.campaignData.message) return false;
      if (!data.campaignData.message2) return false;
      if (!data.campaignData.clientLogo) return false;
      if (!data.campaignData.creaCta) return false;
      if (!data.isInactive) {
        if (!data.activityPeriod || !data.activityPeriod.from) return false;
        if (!data.activityPeriod || !data.activityPeriod.to) return false;
      }
      return true;
    };
};

export const formatDate = (dateString) => {
  return `${dateString.substring(8, 10)}/${dateString.substring(
    5,
    7
  )}/${dateString.substring(0, 4)} à ${dateString.substring(
    11,
    13
  )}:${dateString.substring(14, 16)}`;
};

export const calculateStats = (stats, before, after) => {
  const result = {};

  if (before && after) {
    Object.keys(stats).forEach((key) => {
      let total = 0;
      Object.keys(stats[key]).forEach((date) => {
        if (date >= before && date <= after) {
          total += stats[key][date];
        }
      });
      result[key] = total;
    });
  }

  return result;
};

export const getStatsTable = (stats) => {
  const rawStats = {
    click_desktop: stats.click_desktop || 0,
    click_msite: stats.click_msite || 0,
    impression_desktop: stats.impression_desktop || 0,
    impression_msite: stats.impression_msite || 0,
    impression_android: stats.impression_android || 0,
    click_android: stats.click_android || 0,
  };
  const finalStats = {
    ...rawStats,
    ctr_msite:
      rawStats.impression_msite !== 0
        ? ((rawStats.click_msite / rawStats.impression_msite) * 100).toFixed(
            2
          ) + "%"
        : "-",
    ctr_desktop:
      rawStats.impression_desktop !== 0
        ? (
            (rawStats.click_desktop / rawStats.impression_desktop) *
            100
          ).toFixed(2) + "%"
        : "-",
    click_total:
      rawStats.click_desktop + rawStats.click_msite + rawStats.click_android,
    impression_total:
      rawStats.impression_desktop +
      rawStats.impression_msite +
      rawStats.impression_android,
    ctr_total:
      rawStats.impression_desktop + rawStats.impression_msite !== 0
        ? (
            ((rawStats.click_desktop +
              rawStats.click_msite +
              rawStats.click_android) /
              (rawStats.impression_desktop +
                rawStats.impression_msite +
                rawStats.impression_android)) *
            100
          ).toFixed(2) + "%"
        : "-",
    ctr_android:
      rawStats.impression_android !== 0
        ? (
            (rawStats.click_android / rawStats.impression_android) *
            100
          ).toFixed(2) + "%"
        : "-",
  };
  return finalStats;
};

export const getNotifAdStats = (stats) => {
  const rawStats = {
    click_bubble_desktop: stats.click_bubble_desktop || 0,
    click_crea_desktop: stats.click_crea_desktop || 0,
    impression_bubble_desktop: stats.impression_bubble_desktop || 0,
    impression_crea_desktop: stats.impression_crea_desktop || 0,
    click_bubble_msite: stats.click_bubble_msite || 0,
    click_crea_msite: stats.click_crea_msite || 0,
    impression_bubble_msite: stats.impression_bubble_msite || 0,
    impression_crea_msite: stats.impression_crea_msite || 0,
  };
  const finalStats = {
    ...rawStats,
    ctr_bubble_desktop:
      rawStats.impression_bubble_desktop !== 0
        ? (
            (rawStats.click_bubble_desktop /
              rawStats.impression_bubble_desktop) *
            100
          ).toFixed(2) + "%"
        : "-",
    ctr_crea_desktop:
      rawStats.impression_crea_desktop !== 0
        ? (
            (rawStats.click_crea_desktop / rawStats.impression_crea_desktop) *
            100
          ).toFixed(2) + "%"
        : "-",
    ctr_total_desktop:
      rawStats.impression_bubble_desktop !== 0
        ? (
            (rawStats.click_crea_desktop / rawStats.impression_bubble_desktop) *
            100
          ).toFixed(2) + "%"
        : "-",
    ctr_bubble_msite:
      rawStats.impression_bubble_msite !== 0
        ? (
            (rawStats.click_bubble_msite / rawStats.impression_bubble_msite) *
            100
          ).toFixed(2) + "%"
        : "-",
    ctr_crea_msite:
      rawStats.impression_crea_msite !== 0
        ? (
            (rawStats.click_crea_msite / rawStats.impression_crea_msite) *
            100
          ).toFixed(2) + "%"
        : "-",
    ctr_total_msite:
      rawStats.impression_bubble_msite !== 0
        ? (
            (rawStats.click_crea_msite / rawStats.impression_bubble_msite) *
            100
          ).toFixed(2) + "%"
        : "-",
  };
  return finalStats;
};

export const getCSVData = (statsTable, startDate, endDate) => {
  return [
    ["Du", "Au"],
    [startDate || "-", endDate || "-"],
    ["", ""],
    ["impressions desktop", statsTable.impression_desktop || 0],
    ["clicks desktop", statsTable.click_desktop || 0],
    ["ctr desktop", statsTable.ctr_desktop || 0],
    ["", ""],
    ["impressions msite", statsTable.impression_msite || 0],
    ["clicks msite", statsTable.click_msite || 0],
    ["ctr msite", statsTable.ctr_msite || 0],
    ["", ""],
    ["impressions total", statsTable.impression_total || 0],
    ["clicks total", statsTable.click_total || 0],
    ["ctr total", statsTable.ctr_total || 0],
  ];
};

export const useJwtToken = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [jwtToken, setJwtToken] = useState(null);

  useEffect(() => {
    const getAccessToken = async () => {
      try {
        const accessToken = await getAccessTokenSilently();
        setJwtToken(accessToken);
      } catch (e) {
        console.log(e.message);
      }
    };

    getAccessToken();
  }, [getAccessTokenSilently]);

  return jwtToken;
};
