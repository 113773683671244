import React from "react";
import Creatable from "react-select/creatable";
import Select, { components } from "react-select";
import theme from "theme";
import styled from "styled-components";

const Label = styled.div`
  margin-bottom: 12px;
  font-size: 12px;
  font-weight: 600;
`;

const customStyles = {
  control: (base) => ({
    ...base,
    background: theme.colors.lightBlue,
    borderRadius: theme.standardRadius,
    border: "none",
    boxShadow: "inset 0px 2px 2px rgba(0, 0, 0, 0.07)",
    paddingLeft: 7,
    paddingTop: 5,
    paddingBottom: 5,
    fontWeight: 600,
    minHeight: 40,
  }),
  multiValue: (base) => ({
    ...base,
    background: "#fff",
    border: "1px solid lightgray",
  }),
  option: (base) => ({
    ...base,
    ":active": {
      backgroundColor: "#deebff",
    },
  }),
};

const GroupHeading = styled.div`
  font-size: 1.2em;
  font-weight: bold;
  margin-bottom: 0.5em;
`;

const Option = styled.div`
  margin-left: 1.5em;
  color: ${(props) => (props.isSelected ? "white" : "black")};
  padding: 0.5em;
  background-color: ${(props) =>
    props.isFocused || props.isParentFocused ? "#deebff" : "white"};
`;

const CustomGroupHeading = (props) => (
  <components.GroupHeading {...props}>
    <GroupHeading>{props.children}</GroupHeading>
  </components.GroupHeading>
);

const CustomOption = (props) => (
  <components.Option {...props}>
    <Option
      isSelected={props.isSelected}
      isFocused={props.isFocused}
      isParentFocused={props.isParentFocused}
    >
      {props.children}
    </Option>
  </components.Option>
);

const InputSelect = ({
  label,
  options,
  style,
  withCreate,
  multiple,
  clearable = true,
  ...rest
}) => {
  const SelectElement = withCreate ? Creatable : Select;

  return (
    <div style={style}>
      {label && <Label>{label}</Label>}
      <SelectElement
        components={{
          GroupHeading: CustomGroupHeading,
          Option: CustomOption,
        }}
        isMulti={multiple}
        isClearable={clearable}
        formatCreateLabel={(val) => <span>Nouveau client: {val}</span>}
        styles={customStyles}
        options={options}
        {...rest}
      />
    </div>
  );
};

export default InputSelect;
