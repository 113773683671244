import React, { useState, useEffect } from "react";
import styled from "styled-components";

import { ModalContent, ModalFooter, Separator } from "components/Modal";
import Button from "components/Button";

import { handleChangeAndCleanEmpty, createCampaignValidator } from "helpers";
import { remove, update } from "api";
import { useJwtToken } from "helpers";
import { NOTIF_CONTENT } from "config";

const CP_LEAD_URL = "https://cplead.avito.ma/CPL/notif-cplead/show_leads.php";

export default ({
  formFields: FormFields,
  initialData,
  closeModal,
  refresh,
  adFormatName,
}) => {
  const [data, setData] = useState(initialData);
  const [hasDataChanged, setHasDataChanged] = useState(false);
  const [loadingSave, setLoadingSave] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const jwtToken = useJwtToken();

  useEffect(() => {
    if (data !== initialData && !hasDataChanged) setHasDataChanged(true);
  }, [data, hasDataChanged, initialData]);

  const handleChange = (property, value) =>
    handleChangeAndCleanEmpty(property, value, data, (res) => setData(res));

  const handleSubmit = () => {
    setLoadingSave(true);
    const { _id, ...dataToUpdate } = data;
    if (_id)
      update(_id, dataToUpdate, jwtToken)
        .then(() => {
        /* eslint-disable */
          refresh?.();
          closeModal?.();
        /* eslint-enable */
        })
        .catch(console.log);
  };

  const handleDelete = () => {
    setLoadingDelete(true);
    if (data._id)
      remove(data._id, jwtToken)
        .then(() => {
          refresh();
          closeModal();
        })
        .catch(console.log);
  };

  const isValid = createCampaignValidator(adFormatName.short, data);

  return (
    <>
      <ModalContent>
        {initialData?.type === "notifad" &&
        initialData?.campaignData?.type === NOTIF_CONTENT.FORM ? (
          <>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={`${CP_LEAD_URL}?campaign=${initialData?.name}`}
            >
              Leads pour cette campagne
            </a>

            <Separator style={{ marginBottom: 16, marginTop: 16 }} />
          </>
        ) : null}
        <FormFields
          data={data}
          handleChange={handleChange}
          initialData={initialData}
          adFormatName={adFormatName}
        />
        <Separator style={{ marginBottom: 16, marginTop: 16 }} />
        <DeleteButton onClick={() => setShowDeleteConfirm(true)}>
          Supprimer cette campagne
        </DeleteButton>
      </ModalContent>
      {showDeleteConfirm && (
        <ModalFooter>
          <div
            style={{
              color: "red",
              fontWeight: 600,
              textAlign: "center",
              margin: "25px 0",
            }}
          >
            Êtes-vous sur de vouloir supprimer cette campagne ?
          </div>
          <ModalFooterContent center style={{ paddingBottom: 25 }}>
            <Button empty onClick={() => setShowDeleteConfirm(false)}>
              annuler
            </Button>
            <Button
              danger
              onClick={handleDelete}
              disabled={loadingDelete}
              loading={loadingDelete}
            >
              Confirmer la suppression
            </Button>
          </ModalFooterContent>
        </ModalFooter>
      )}

      {!showDeleteConfirm && hasDataChanged && (
        <ModalFooter>
          <ModalFooterContent>
            <Button empty onClick={closeModal}>
              annuler
            </Button>
            <Button
              primary
              onClick={handleSubmit}
              disabled={loadingSave || !isValid()}
              loading={loadingSave}
            >
              enregistrer les modifications
            </Button>
          </ModalFooterContent>
        </ModalFooter>
      )}
    </>
  );
};

// Styles

const ModalFooterContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${({ center }) => (center ? "center" : "flex-end")};
  height: 100%;
`;

const DeleteButton = styled.button`
  display: block;
  margin: 50px auto;
  padding: 0 30px;
  height: 40px;
  color: red;
  background: #f5f5f5;
  border-radius: ${({ theme }) => theme.standardRadius};
`;
