import React from "react";
import Input from "./Input";
import InputSelect from "./InputSelect";
import DateTimeInput from "./DateTimeInput";
import Checkbox from "./Checkbox";

const CampaignDataFormFields = ({ data, handleChange, clients }) => {
  return (
    <div>
      <Input
        label="Nom de la campagne"
        style={{ marginBottom: 36 }}
        value={data.name || ""}
        onChange={e => handleChange("name", e.target.value)}
      />
      <InputSelect
        label="Client"
        withCreate
        options={clients.map(client => ({ value: client, label: client }))}
        placeholder="Selectioner un client"
        style={{ marginBottom: 36 }}
        value={data.client ? { value: data.client, label: data.client } : null}
        onChange={data =>
          data ? handleChange("client", data.value) : handleChange("client", "")
        }
      />
      <Checkbox
        options={[{ value: "inactive", label: "Campagne désactivée" }]}
        value={data.isInactive ? ["inactive"] : []}
        onChange={val =>
          handleChange("isInactive", val.length > 0 ? true : false)
        }
        style={{ marginBottom: 36 }}
      />
      <div
        style={{
          transition: "opacity 0.15s",
          opacity: data.isInactive ? 0.5 : 1
        }}
      >
        <DateTimeInput
          value={data.activityPeriod ? data.activityPeriod.from : null}
          onChange={date => handleChange("activityPeriod.from", date)}
          label="Lancement de la campange"
          style={{ marginBottom: 36 }}
        />
        <DateTimeInput
          value={data.activityPeriod ? data.activityPeriod.to : null}
          onChange={date => handleChange("activityPeriod.to", date)}
          label="Arrêt de la campagne"
          style={{ marginBottom: 36 }}
        />
      </div>
    </div>
  );
};

export default CampaignDataFormFields;
