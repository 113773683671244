import storage from "firebase-config";
import firebase from "firebase/app";
import "firebase/auth";

const API_URL = "/campaigns";
const FIREBASE_URL = "/auth/firebase";

export const getAll = (type) => {
  const query = type ? "?type=" + type : "";
  return fetch(API_URL + "/" + query).then((res) => res.json());
};

export const updateStat = async (id, name) => {
  const response = await fetch(`${API_URL}/${id}/stats/${name}`);
  const json = await response.json();
  return json;
};

const getFirebaseToken = async (jwtToken) => {
  if (!jwtToken) {
    return;
  }

  let res, json;
  try {
    res = await fetch(FIREBASE_URL, {
      headers: {
        Authorization: `Bearer ${jwtToken}`,
      },
    });

    if (res.ok) {
      json = await res.json();
    }

    return json?.firebaseToken;
  } catch (e) {
    console.log(`Error retrieving firebase token: ${e}`);
  }
};

export const uploadImage = async (img, jwtToken) => {
  const name =
    +new Date() + "-" + Math.floor(Math.random() * 1000) + "-" + img.name;
  const metadata = {
    contentType: img.type,
  };

  // authenticate to firebase
  const customToken = await getFirebaseToken(jwtToken);
  if (customToken) {
    return firebase
      .auth()
      .signInWithCustomToken(customToken)
      .then((_user) => {
        // firebase auth successful
        console.log("Signed in successfully");
        return storage
          .child(name)
          .put(img, metadata)
          .then((snapshot) => snapshot.ref.getDownloadURL());
      })
      .catch((error) => {
        console.log(`Error authenticating with firebase: ${error.message}`);
        return;
      });
  }
};

// with auth

export const getClients = async (jwtToken) => {
  if (!jwtToken) return [];

  let res, json;
  try {
    res = await fetch(`${API_URL}/clients`, {
      headers: {
        Authorization: `Bearer ${jwtToken}`,
      },
    });

    json = await res.json();

    return json;
  } catch (e) {
    console.log(`Error in getClients: ${e}`);
  }
};

export const create = (data, jwtToken) => {
  return fetch(API_URL, {
    method: "post",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
      Authorization: `Bearer ${jwtToken}`,
    },
    body: JSON.stringify(data),
  }).then((res) => res.json());
};

export const update = (id, data, jwtToken) => {
  return fetch(API_URL + "/" + id, {
    method: "PATCH",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
      Authorization: `Bearer ${jwtToken}`,
    },
    body: JSON.stringify(data),
  }).then((res) => res.json());
};

export const remove = (id, jwtToken) => {
  return fetch(API_URL + "/" + id, {
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${jwtToken}`,
    },
  }).then((res) => res.json());
};
