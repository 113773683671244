import React, { useState, useEffect } from "react";

import Shell from "components/Shell";
import { getAll } from "api";
import { CAMPAIGN_NAMES, HEADERS } from "config";

const props = {
  adFormatName: CAMPAIGN_NAMES.TP,
  tableHeaders: HEADERS.TP,
};

export default () => {
  const [data, setData] = useState([]);

  const refresh = () => {
    getAll(CAMPAIGN_NAMES.TP.short)
      .then((data) => setData(data?.ads ?? []))
      .catch(console.log);
  };

  useEffect(() => {
    refresh();
  }, []);

  return (
    <Shell
      {...props}
      campaignsData={data}
      refresh={refresh}
      defaultValues={{ campaignData: { creaCta: "Profiter de l'offre" } }}
    />
  );
};
