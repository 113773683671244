import React, { useState, useEffect } from "react";
import Shell from "components/Shell";
import { getAll } from "api";
import { CAMPAIGN_NAMES, HEADERS } from "config";

const props = {
  adFormatName: CAMPAIGN_NAMES.LOAN,
  tableHeaders: HEADERS.LOAN,
  defaultValues: {
    campaignData: {
      clientLogo: 'https://firebasestorage.googleapis.com/v0/b/landing-gen-dev-25ef8.appspot.com/o/wafasalaf_200x200.png?alt=media&token=4b9cd831-7098-441c-b49f-270df40a9031',
      rate: 8.8,
      months: 68
    },
  },
};

export default () => {
  const [data, setData] = useState([]);

  const refresh = () => {
    getAll(CAMPAIGN_NAMES.LOAN.short)
      .then((data) => setData(data?.ads ?? []))
      .catch(console.log);
  };

  useEffect(() => {
    refresh();
  }, []);

  return <Shell {...props} campaignsData={data} refresh={refresh} />;
};
