import React from "react";
import { ThemeProvider } from "styled-components";
import Header from "pages/Header";
import TouchingPoints from "pages/TouchingPoints";
import NotifAd from "pages/NotifAd";
import Loan from "pages/Loan";
import Slide from "pages/Slide";
import Home from "pages/Home";
import LoginCreative from "pages/LoginCreative";
import Insurance from "pages/Insurance";

import { createBrowserHistory } from "history";
import theme from "theme";
import { BrowserRouter, Route } from "react-router-dom";
import { Auth0Provider, withAuthenticationRequired } from "@auth0/auth0-react";

const authConfig = {
  domain: "dev-ufl8zmp3.us.auth0.com",
  clientId: "dfWc916AH4P268Uf1jUVW6TMzCqSoVAW",
};

const history = createBrowserHistory();

const ProtectedRoute = ({ component, ...args }) => (
  <Route component={withAuthenticationRequired(component)} {...args} />
);

const onRedirectCallback = (appState) => {
  // Use the router's history module to replace the url
  history.replace(appState?.returnTo || window.location.pathname);
};

function App() {
  return (
    <Auth0Provider
      domain={authConfig.domain}
      clientId={authConfig.clientId}
      redirectUri={window.location.origin}
      onRedirectCallback={onRedirectCallback}
      audience="CompaignsCRUD"
    >
      <BrowserRouter history={history}>
        <ThemeProvider theme={theme}>
          <ProtectedRoute path="/tp" exact component={TouchingPoints} />
          <ProtectedRoute path="/notifad" exact component={NotifAd} />
          <ProtectedRoute path="/header" exact component={Header} />
          <ProtectedRoute path="/loan" exact component={Loan} />
          <ProtectedRoute path="/slide" exact component={Slide} />
          <ProtectedRoute path="/login" exact component={LoginCreative} />
          <ProtectedRoute path="/insurance" exact component={Insurance} />
          <Route path="/" exact component={Home} />
        </ThemeProvider>
      </BrowserRouter>
    </Auth0Provider>
  );
}

export default App;
