import React from "react";
import styled from "styled-components";

const Table = ({ headers, rows, tabs, activeTab, onTabChange, onRowClick }) => {
  const displayCell = (row, header) => {
    const rowData = row[header.key];
    if (header.customRender) return header.customRender(rowData, row);
    return rowData;
  };

  return (
    <>
      {tabs && (
        <TableTabs>
          {tabs.map((tab, i) => (
            <div
              style={tab.color && { color: tab.color }}
              key={`tabletab_${i}`}
              className={"tableTab" + (i === activeTab ? " active" : "")}
              onClick={() => onTabChange(i)}
            >
              {tab.text || tab}
            </div>
          ))}
        </TableTabs>
      )}
      <StyledTable>
        <div className="headers">
          {headers.map((header, i) => (
            <div
              className="cell"
              style={header.width && { flex: "0 0 " + header.width + "px" }}
              key={`header_${i}`}
            >
              {header.text || header}
            </div>
          ))}
        </div>
        <div className="rows">
          {rows.map((row, i) => (
            <div
              onClick={() => onRowClick(row)}
              className="row"
              key={`row_${i}`}
            >
              {headers.map((header, j) => (
                <div
                  style={header.width && { flex: "0 0 " + header.width + "px" }}
                  className="cell"
                  key={`row_${i}_cell_${j}`}
                >
                  {displayCell(row, header)}
                </div>
              ))}
            </div>
          ))}
        </div>
      </StyledTable>
    </>
  );
};

export default Table;

// Styles

const StyledTable = styled.div`
  border-radius: ${({ theme }) => theme.standardRadius};
  background: white;
  border: 1px solid ${({ theme }) => theme.colors.lightGrayBorder};
  position: relative;
  z-index: 2;
  .headers,
  .row {
    display: flex;
    border-bottom: 1px solid ${({ theme }) => theme.colors.lightGrayBorder};
    > div {
      flex: 1;
    }
  }

  .row {
    background: white;
    transition: transform 0.15s;
    cursor: pointer;
    border-radius: ${({ theme }) => theme.standardRadius};
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    &:hover {
      position: relative;
      border-bottom-right-radius: ${({ theme }) => theme.standardRadius};
      border-bottom-left-radius: ${({ theme }) => theme.standardRadius};
      z-index: 2;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15),
        0 0 0 1px ${({ theme }) => theme.colors.lightGrayBorder};
      transform: scale(1.02);
    }
    &:active {
      transform: scale(1);
    }
    &:last-child {
      border-bottom: 0;
      border-bottom-right-radius: ${({ theme }) => theme.standardRadius};
      border-bottom-left-radius: ${({ theme }) => theme.standardRadius};
    }
  }

  .headers {
    font-weight: bold;
    font-size: 12px;
  }

  .cell {
    padding: 12px 20px;
    border-right: 1px solid ${({ theme }) => theme.colors.lightGrayBorder};
    display: flex;
    align-items: center;
    &:last-child {
      border-right: none;
    }
  }
`;

const TableTabs = styled.div`
  margin-bottom: -20px;
  display: flex;
  font-weight: bold;
  font-size: 12px;
  .tableTab {
    border: 1px solid transparent;
    cursor: pointer;
    padding: 8px 15px;
    border-radius: ${({ theme }) => theme.standardRadius};
    padding-bottom: 28px;
    opacity: 0.5;
    transition: opacity 0.15s;
    &:hover {
      opacity: 1;
    }
    &.active {
      opacity: 1;
      background: white;
      border: 1px solid ${({ theme }) => theme.colors.lightGrayBorder};
    }
  }
`;
