export const cities = [
  { label: "Tout le Maroc", value: "3" },
  { label: "Casablanca", value: "5" },
  { label: "Agadir", value: "13" },
  { label: "Al Hoceima", value: "17" },
  { label: "Béni Mellal", value: "14" },
  { label: "El Jadida", value: "7" },
  { label: "Errachidia", value: "18" },
  { label: "Fès", value: "3" },
  { label: "Kénitra", value: "4" },
  { label: "Khénifra", value: "9" },
  { label: "Khouribga", value: "1" },
  { label: "Larache", value: "20" },
  { label: "Marrakech", value: "8" },
  { label: "Meknès", value: "9" },
  { label: "Nador", value: "21" },
  { label: "Ouarzazate", value: "22" },
  { label: "Oujda", value: "10" },
  { label: "Rabat", value: "12" },
  { label: "Safi", value: "2" },
  { label: "Settat", value: "23" },
  { label: "Salé", value: "6" },
  { label: "Tanger", value: "15" },
  { label: "Taza", value: "16" },
  { label: "Tétouan", value: "11" },
  { label: "Mohammedia", value: "90" },
];
