import React, { useState, useRef } from "react";
import styled from "styled-components";
import Button from "components/Button";
import { ReactComponent as LoadIcon } from "assets/load.svg";
import { uploadImage } from "api";

const StyledUploadButton = styled.div`
  .label {
    margin-bottom: 12px;
    font-size: 12px;
    font-weight: 600;
  }
  .preview {
    display: block;
    border-radius: 3px;
    max-height: 200px;
    max-width: 100%;
    border: 1px solid ${({ theme }) => theme.colors.lightGrayBorder};
  }
`;

const UploadButton = ({ onClick, readOnly, label, onUpload, url, jwtToken, ...rest }) => {
  const [isUploading, setIsUploading] = useState(false);
  const inputRef = useRef();

  const handleButtonClick = () => {
    inputRef.current.click();
  };

  const handleImageSelect = (e) => {
    setIsUploading(true);
    uploadImage(e.target.files[0], jwtToken)
      .then((url) => {
        setIsUploading(false);
        onUpload(url);
      })
      .catch((e) => console.error("error uploading", e));
  };

  return (
    <StyledUploadButton {...rest}>
      {label && <div className="label">{label}</div>}
      {url && (
        <a target="__blank" href={url} onClick={onClick}>
          <img className="preview" src={url} alt="preview upload" />
        </a>
      )}
      <Button
        type="file"
        secondary
        disabled={isUploading || readOnly}
        onClick={handleButtonClick}
        style={
          url
            ? {
                borderTopLeftRadius: 0,
                borderTopRightRadius: 0,
              }
            : {}
        }
      >
        {isUploading ? "En cours ..." : url ? "Changer d'image" : "Upload"}
        {isUploading && (
          <LoadIcon
            height="1.6em"
            style={{ animation: "rotate 1s infinite linear", marginLeft: 16 }}
          />
        )}
        <input hidden type="file" onChange={handleImageSelect} ref={inputRef} />
      </Button>
      <Button
        danger
        onClick={() => {
          inputRef.current.value = "";
          onUpload(undefined);
        }}
        disabled={!url || readOnly}
      >
        Effacer
      </Button>
    </StyledUploadButton>
  );
};

export default UploadButton;
