import React from "react";

const pillStyleActive = {
  display: "inline-block",
  background: "#63A861",
  borderRadius: "30px",
  color: "white",
  fontWeight: "bold",
  fontSize: 9,
  letterSpacing: "0.05em",
  textTransform: "uppercase",
  boxShadow: "0px 0px 0px 4px #63A861",
  padding: "0 3px"
};

const pillStyleInactive = {
  color: "gray",
  fontWeight: "bold",
  fontSize: 9,
  letterSpacing: "0.05em",
  textTransform: "uppercase",
  padding: "0 3px"
};

const pillStyleDisabled = {
  color: "red",
  fontWeight: "bold",
  fontSize: 9,
  letterSpacing: "0.05em",
  textTransform: "uppercase",
  padding: "0 3px"
};

export const ActivePill = () => <div style={pillStyleActive}>Active</div>;
export const InactivePill = () => <div style={pillStyleInactive}>Inactive</div>;
export const DisabledPill = () => (
  <div style={pillStyleDisabled}>Désactivée</div>
);
