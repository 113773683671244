import React, { useState, useEffect } from "react";
import styled from "styled-components";

const NativeSelect = styled.select`
  appearance: none;
  height: 40px;
  background: ${({ theme }) => theme.colors.lightBlue};
  border-radius: ${({ theme }) => theme.standardRadius};
  border: none;
  box-shadow: inset 0px 2px 2px rgba(0, 0, 0, 0.07);
  font-size: inherit;
  font-family: inherit;
  color: inherit;
  font-weight: bold;
  padding: 7px 15px;
  &:first-child {
    border-radius: ${({ theme }) => theme.standardRadius} 0 0
      ${({ theme }) => theme.standardRadius};
    border-right: 1px solid lightgray;
  }
  &:nth-child(2) {
    border-radius: 0;
  }
  &:last-child {
    border-radius: 0 ${({ theme }) => theme.standardRadius}
      ${({ theme }) => theme.standardRadius} 0;
    border-left: 1px solid lightgray;
  }
`;

const Label = styled.div`
  margin-bottom: 12px;
  font-size: 12px;
  font-weight: 600;
`;

const Container = styled.div`
  display: flex;
`;

const getDays = () => {
  const days = [];
  for (let i = 1; i < 32; i++) {
    days.push(padZero(i));
  }
  return days;
};

const padZero = num => {
  return parseInt(num) > 9 ? parseInt(num) : `0${parseInt(num)}`;
};

const getMonths = () => {
  const months = [
    "Janvier",
    "Fevrier",
    "Mars",
    "Avril",
    "May",
    "Juin",
    "Juillet",
    "Aout",
    "Septembre",
    "Octobre",
    "Novembre",
    "Decembre"
  ];
  return months.map((val, i) => ({ value: padZero(i + 1), label: val }));
};

const getYears = () => {
  const years = [
    "2020",
    "2021",
    "2022",
    "2023",
    "2024",
    "2025",
    "2026",
    "2027",
    "2028",
    "2029"
  ];
  return years;
};

const deconstructDate = date => {
  return {
    year: date.substring(0, 4),
    month: date.substring(5, 7),
    day: date.substring(8, 10)
  };
};

export default ({ value, onChange, label, ...rest }) => {
  const [data, setData] = useState(
    value
      ? deconstructDate(value)
      : {
          day: "",
          month: "",
          year: ""
        }
  );

  useEffect(() => {
    if (data.day && data.month && data.year) {
      onChange(`${data.year}-${padZero(data.month)}-${padZero(data.day)}`);
    }
  }, [data, onChange]);

  return (
    <>
      <Label>{label}</Label>
      <Container {...rest}>
        <NativeSelect
          value={data.day}
          onChange={e => setData({ ...data, day: e.target.value })}
        >
          <option value="" disabled></option>
          {getDays().map(day => (
            <option key={`day_${day}`} value={day}>
              {day}
            </option>
          ))}
        </NativeSelect>
        <NativeSelect
          value={data.month}
          onChange={e => setData({ ...data, month: e.target.value })}
        >
          <option value="" disabled></option>
          {getMonths().map(month => (
            <option key={`month_${month.value}`} value={month.value}>
              {month.label}
            </option>
          ))}
        </NativeSelect>
        <NativeSelect
          value={data.year}
          onChange={e => setData({ ...data, year: e.target.value })}
        >
          <option value="" disabled></option>
          {getYears().map(year => (
            <option key={`year_${year}`} value={year}>
              {year}
            </option>
          ))}
        </NativeSelect>
      </Container>
    </>
  );
};
