import firebase from "firebase/app";
import "firebase/storage";

var firebaseConfig = {
  apiKey: "AIzaSyBL2MZxHwj3d7JLHoU-UvHGcmu7-5wRf9w",
  authDomain: "landing-gen-dev-25ef8.firebaseapp.com",
  databaseURL: "https://landing-gen-dev-25ef8.firebaseio.com",
  projectId: "landing-gen-dev-25ef8",
  storageBucket: "landing-gen-dev-25ef8.appspot.com",
  messagingSenderId: "221392219284",
  appId: "1:221392219284:web:255470c441466fb2079881",
  measurementId: "G-BNXC4XKF7C",
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

const storage = firebase.storage().ref();
export default storage;
