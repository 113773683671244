import React, { useEffect, useState } from "react";
import Input from "./Input";
import Checkbox from "./Checkbox";
import InputSelect from "./InputSelect";
import { cities } from "data.js";

const withAdditionalPages = (arr = []) => {
  const newArr = [...arr];

  // eslint-disable-next-line no-unused-expressions
  newArr?.unshift(
    {
      value: "home",
      label: "Page d'accueil",
    },
    {
      value: "autoneuf",
      label: "Auto Neuf",
    }
  );

  return newArr;
};

const normalizeCategories = (data) => {
  const categoriesTree = data.slice(1);

  return categoriesTree.map((item) => ({
    label: item.name,
    options: [
      {
        value: item.name,
        label: "Tout sélectionner",
        isSelectAll: true,
      },
      ...item.children.map((child) => ({
        value: child.category.id,
        label: child.name,
      })),
    ],
  }));
};

export default ({ data, onChange, hideable = true }) => {
  const [show, setShow] = useState([]);
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    fetch("https://services.avito.ma/api/v2/config/adlisting/tree?lang=fr", {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    })
      .then((response) => response.json())
      .then((data) => setCategories(normalizeCategories(data)))
      .catch((error) => {
        console.error(
          "Encountered an error while getting listing category tree",
          {
            error: error?.message || error,
          }
        );
      });
  }, []);

  const removeSelectAllOption = (categoryLabel) => {
    setCategories((prevCategories) =>
      prevCategories.map((category) => {
        if (category.label === categoryLabel) {
          return {
            ...category,
            options: category.options.filter((option) => !option.isSelectAll),
          };
        }
        return category;
      })
    );
  };

  const addSelectAllOption = (categoryLabel) => {
    setCategories((prevCategories) =>
      prevCategories.map((category) => {
        if (category.label === categoryLabel) {
          return {
            ...category,
            options: [
              {
                value: categoryLabel,
                label: "Select All",
                isSelectAll: true,
              },
              ...category.options.filter((option) => !option.isSelectAll),
            ],
          };
        }
        return category;
      })
    );
  };

  const handleChange = (property, value) => {
    let updatedValue;
    const newCategoryCounts = {};
    const totalOptionsPerCategory = {};

    if (Array.isArray(value) && property === "categories") {
      updatedValue = value.flatMap((selectedOption) => {
        if (selectedOption.isSelectAll) {
          const group = categories.find(
            (group) => group.label === selectedOption.value
          );
          if (group) {
            removeSelectAllOption(group.label);
            return group.options.filter((option) => !option.isSelectAll);
          }
          return selectedOption;
        }
        return selectedOption;
      });

      const uniqueSelectedOptions = new Set(
        updatedValue.map((option) => option.value)
      );
      updatedValue = Array.from(uniqueSelectedOptions).map((value) =>
        updatedValue.find((option) => option.value === value)
      );
    } else {
      updatedValue = value;
    }

    if (property === "categories") {
      if (Array.isArray(updatedValue) && updatedValue.length > 0) {
        updatedValue.forEach((selectedOption) => {
          categories.forEach((category) => {
            if (
              category.options.some(
                (option) => option.value === selectedOption.value
              )
            ) {
              newCategoryCounts[category.label] =
                (newCategoryCounts[category.label] || 0) + 1;
            }
          });
        });
      }

      categories.forEach((category) => {
        totalOptionsPerCategory[category.label] = category.options.length - 1;
      });

      Object.keys(totalOptionsPerCategory).forEach((categoryLabel) => {
        const selectedCount = newCategoryCounts[categoryLabel] || 0;
        const totalOptions = totalOptionsPerCategory[categoryLabel];
        if (totalOptions - selectedCount >= 1) {
          addSelectAllOption(categoryLabel);
        } else {
          removeSelectAllOption(categoryLabel);
        }
      });
    }

    const newData = { ...data };
    newData[property] = updatedValue;
    // Clean empty object entries
    Object.keys(newData).forEach((key) => {
      if (!newData[key] || newData[key] === "") delete newData[key];
    });

    onChange(newData);
  };

  return (
    <>
      {hideable ? (
        <Checkbox
          options={[{ value: "show", label: "Activer le targeting avancé" }]}
          value={show}
          onChange={(val) => {
            setShow(val);
            if (val.length === 0) onChange({});
          }}
          style={{ marginBottom: 36 }}
        />
      ) : (
        <div style={{ marginBottom: 36, fontWeight: 600 }}>
          Targeting avancé
        </div>
      )}
      {(!hideable || show.length > 0) && (
        <>
          <InputSelect
            label="Categories"
            options={withAdditionalPages(categories)}
            multiple
            clearable={false}
            onChange={(categories) => handleChange("categories", categories)}
            value={data.categories || []}
            placeholder="Toutes les categories"
            style={{ marginBottom: 36 }}
          />
          <InputSelect
            label="Villes"
            options={cities}
            clearable={false}
            multiple
            onChange={(cities) => handleChange("cities", cities)}
            value={data.cities || []}
            placeholder="Toutes les villes"
            style={{ marginBottom: 36 }}
          />
          <div style={{ display: "flex" }}>
            <Input
              value={data.priceMin || ""}
              onChange={(e) => handleChange("priceMin", e.target.value)}
              style={{
                marginRight: 20,
                opacity: data.priceMin?.length > 0 ? 1 : 0.5,
              }}
              type="number"
              label="Prix min"
            />
            <Input
              value={data.priceMax || ""}
              onChange={(e) => handleChange("priceMax", e.target.value)}
              style={{
                marginLeft: 20,
                opacity: data.priceMax?.length > 0 ? 1 : 0.5,
              }}
              type="number"
              label="Prix max"
            />
          </div>
        </>
      )}
    </>
  );
};
